import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";
import Spinner from "../BaseFile/comman/Spinner";
import { addDeposite } from "../redux/depositeSlice";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3ModalState,
  useDisconnect,
} from "@web3modal/ethers/react";

// Configure Web3Modal
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "USDT Transfer App",
      description: "Transfer USDT across different networks",
    },
  }),
  chains: [
    {
      chainId: 56,
      name: "BNB Smart Chain",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: "https://bsc-dataseed.binance.org",
    },
  ],
  projectId: "b00311bb20f1d71b977b474eac2b7dcd", // Get this from cloud.walletconnect.com
});

const USDT_CONTRACT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";

const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
  "function transfer(address to, uint amount) returns (bool)",
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",
  "function name() view returns (string)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address spender, uint256 amount) returns (bool)",
];

export default function UserDepositeModel({ openModel, modelClose }) {
  const dispatch = useDispatch();
  const { qr } = useSelector((state) => state.qr);
  const { auth } = useSelector((state) => state.auth);

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useWeb3ModalAccount();
  const { chainId } = useWeb3ModalState();
  const { walletProvider } = useWeb3ModalProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [amount, setAmount] = useState("");
  const [bnbBalance, setBnbBalance] = useState("0");
  const [usdtBalance, setUsdtBalance] = useState("0");

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    }
  };
  useEffect(() => {
    open();
  }, []);

  const switchToBNBChain = async () => {
    try {
      await walletProvider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }], // BSC Mainnet
      });
    } catch (err) {
      if (err.code === 4902) {
        try {
          await walletProvider?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName: "BNB Smart Chain",
                nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                blockExplorerUrls: ["https://bscscan.com/"],
              },
            ],
          });
        } catch (addError) {
          setError("Failed to add BSC network");
        }
      } else {
        setError("Failed to switch network");
      }
    }
  };

  const fetchBalances = async () => {
    if (!address || !address) return;

    try {
      const ethersProvider = new ethers.BrowserProvider(walletProvider);

      // Get BNB Balance
      const bnbBal = await ethersProvider.getBalance(address);
      setBnbBalance(ethers.formatEther(bnbBal));

      // Get USDT Balance
      const usdtContract = new ethers.Contract(
        USDT_CONTRACT_ADDRESS,
        ERC20_ABI,
        ethersProvider
      );

      const decimals = await usdtContract.decimals();
      const usdtBal = await usdtContract.balanceOf(address);
      setUsdtBalance(ethers.formatUnits(usdtBal, decimals));
    } catch (err) {
      setError("Failed to fetch balances");
    }
  };

//   const handleTransfer = async () => {
//     try {
//       setIsLoading(true);
//       setError("");
//       setSuccess("");

//       if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
//         setError("Please enter a valid amount");
//         return;
//       }

//       if (!qr?.BEB20) {
//         setError("Invalid deposit address");
//         return;
//       }

//       const ethersProvider = new ethers.BrowserProvider(walletProvider);
//       const signer = await ethersProvider.getSigner();

//       const contract = new ethers.Contract(
//         USDT_CONTRACT_ADDRESS,
//         ERC20_ABI,
//         signer
//       );

//       const decimals = await contract.decimals();
//       const amountInWei = ethers.parseUnits(amount.toString(), decimals);

//       // Check user's balance
//       const userBalance = await contract.balanceOf(address);
//       if (userBalance < amountInWei) {
//         setError("Insufficient USDT balance");
//         return;
//       }



// // Initiate transaction
// contract
//   .transfer(qr.BEB20, amountInWei)
//   .then(async (tx) => {
//     setSuccess("Transaction submitted. Waiting for confirmation...");

//     try {
//       const txReceipt = await tx.wait(); // Wait for confirmation

//       if (!txReceipt || !txReceipt.hash) {
//         throw new Error("Transaction receipt missing hash.");
//       }

//       console.log(amount, auth?.id, txReceipt.hash);
//       const formData = new FormData();
//       formData.append("amount", amount);
//       formData.append("user_id", auth?.id);
//       formData.append("hash", txReceipt.hash);
//       formData.append("status", "complete");

//       try {
//         // Dispatch and unwrap the action
//         await dispatch(addDeposite(formData)).unwrap();

//         setSuccess("Transaction confirmed and deposit recorded successfully!");

//         // Fetch updated balances after successful deposit
//         await fetchBalances();

//         // Optional: Reload to update UI if necessary
//         setTimeout(() => {
//           window.location.reload();
//         }, 2000);
//       } catch (dispatchError) {
//         console.error("Error adding deposit:", dispatchError);
//         setSuccess(
//           "Transaction confirmed, but deposit recording failed. Please contact support."
//         );
//       }
//     } catch (waitError) {
//       console.error("Transaction confirmation failed:", waitError);
//       setSuccess("Transaction was sent but not confirmed. Please check manually.");
//     }
//   })
//   .catch((error) => {
//     console.error("Error during transaction:", error);
//     setSuccess("Transaction failed. Please try again.");
//   });


//       // Close modal after successful transaction
//       setTimeout(() => {
//         modelClose();
//         setAmount("");
//         setSuccess("");
//       }, 2000);
//     } catch (err) {
//       console.error("Transfer error:", err);
//       setError(err.message || "Transaction failed. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

  // Update balance when wallet address changes
  
  const handleTransfer = async () => {
    try {
      setIsLoading(true);
      setError("");
      setSuccess("");
  
      // Input validation
      if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
        setError("Please enter a valid amount");
        return;
      }
  
      if (!qr?.BEB20) {
        setError("Invalid deposit address");
        return;
      }
  
      // Check if MetaMask is busy with another transaction
      try {
        // This will throw if there's a pending transaction
        const pendingRequests = await window.ethereum._metamask.isUnlocked();
        if (!pendingRequests) {
          setError("Another transaction is pending in MetaMask. Please complete it first.");
          return;
        }
      } catch (e) {
        // Continue if this check fails, as not all providers support this method
      }
  
      const ethersProvider = new ethers.BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
  
      const contract = new ethers.Contract(
        USDT_CONTRACT_ADDRESS,
        ERC20_ABI,
        signer
      );
  
      const decimals = await contract.decimals();
      const amountInWei = ethers.parseUnits(amount.toString(), decimals);
  
      // Check user's balance
      const userBalance = await contract.balanceOf(address);
      if (userBalance < amountInWei) {
        setError("Insufficient USDT balance");
        return;
      }
  
      // Set transaction timeout handler
      let transactionTimeout = setTimeout(() => {
        setError("Transaction request timed out. Please try again later.");
        setIsLoading(false);
      }, 60000); // 1 minute timeout
  
      // Initiate transaction with retry logic
      let retryCount = 0;
      const maxRetries = 2;
      
      const attemptTransaction = async () => {
        try {
          const tx = await contract.transfer(qr.BEB20, amountInWei);
          clearTimeout(transactionTimeout);
          
          setSuccess("Transaction submitted. Waiting for confirmation...");
          
          const txReceipt = await tx.wait();
          
          if (!txReceipt || !txReceipt.hash) {
            throw new Error("Transaction receipt missing hash.");
          }
          
          console.log(amount, auth?.id, txReceipt.hash);
          const formData = new FormData();
          formData.append("amount", amount);
          formData.append("user_id", auth?.id);
          formData.append("hash", txReceipt.hash);
          formData.append("status", "complete");
          
          try {
            await dispatch(addDeposite(formData)).unwrap();
            setSuccess("Transaction confirmed and deposit recorded successfully!");
            await fetchBalances();
            
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } catch (dispatchError) {
            console.error("Error adding deposit:", dispatchError);
            setSuccess("Transaction confirmed, but deposit recording failed. Please contact support.");
          }
          
          // Close modal after successful transaction
          setTimeout(() => {
            modelClose();
            setAmount("");
            setSuccess("");
          }, 2000);
          
        } catch (error) {
          clearTimeout(transactionTimeout);
          
          // Check if it's the pending transaction error
          if (error.message && error.message.includes("already pending")) {
            if (retryCount < maxRetries) {
              retryCount++;
              setSuccess(`MetaMask is busy. Retrying in 5 seconds... (${retryCount}/${maxRetries})`);
              
              // Wait 5 seconds before retrying
              await new Promise(resolve => setTimeout(resolve, 5000));
              return attemptTransaction();
            } else {
              setError("MetaMask is processing another transaction. Please complete it first and try again.");
            }
          } else {
            console.error("Error during transaction:", error);
            setError(error.message || "Transaction failed. Please try again.");
          }
        }
      };
      
      await attemptTransaction();
      
    } catch (err) {
      console.error("Transfer error:", err);
      setError(err.message || "Transaction failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (isConnected && address) {
      fetchBalances();
    }
  }, [isConnected, address, chainId]);

  function handleCancel() {
    modelClose();
    disconnect();
  }
  return (
    <Dialog open={openModel} onClose={modelClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-blue-900/50 border px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="p-5">
              <div className="pb-5 flex justify-between items-center">
                <h2 className="text-xl font-semibold mb-5 text-gray-300">
                  Deposit USDT
                </h2>
                <button
                  onClick={modelClose}
                  className="group flex cursor-pointer items-center justify-center mb-2 bg-blue-900 h-[50px] w-[50px]"
                >
                  <div className="space-y-2">
                    <span className="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
                    <span className="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
                  </div>
                </button>
              </div>

              <div className="space-y-4">
                {!isConnected ? (
                  <div className="text-gray-200 text-center">
                    Please connect your wallet in the main page
                  </div>
                ) : (
                  <>
                    <div className="text-gray-200">
                      <p className="text-sm">
                        Connected: {address?.slice(0, 6)}...
                        {address?.slice(-4)}
                      </p>
                      <p className="text-sm">Balance: {usdtBalance} USDT</p>
                      <p className="text-sm">Balance: {bnbBalance} BNB</p>
                    </div>
                    <div className="space-y-2">
                      <label
                        htmlFor="depositAddress"
                        className="block text-sm font-medium text-gray-200"
                      >
                        Deposit Address
                      </label>
                      <div className="flex items-center justify-between space-x-2 text-white border rounded-sm p-2">
                        <p className="text-sm truncate">{qr?.BEB20}</p>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="amount"
                        className="block text-sm font-medium text-gray-200"
                      >
                        Amount (USDT)
                      </label>
                      <input
                        id="amount"
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        className="mt-1 block w-full text-sm border-gray-200 text-gray-200 rounded-md px-3 py-2 bg-blue-900 shadow-sm focus:ring-opacity-50"
                        placeholder="Enter amount..."
                      />
                    </div>

                    {error && (
                      <div className="text-red-500 text-sm">{error}</div>
                    )}

                    {success && (
                      <div className="text-green-500 text-sm">{success}</div>
                    )}

                    <div className="grid md:grid-cols-2 gap-5">
                      <button
                        onClick={handleCancel}
                        disabled={isLoading}
                        className="w-full px-4 py-2 text-sm bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-indigo-400 disabled:cursor-not-allowed"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleTransfer}
                        disabled={isLoading || !amount}
                        className="w-full px-4 py-2 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-indigo-400 disabled:cursor-not-allowed"
                      >
                        {isLoading ? <Spinner /> : "Confirm Deposit"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
