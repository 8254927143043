import React from "react";

export const AboutSection = () => {
  return (
    <div>
      <section class="pb-16 pt-10 relative bg-[#f3f3f3]">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div class="w-full justify-start items-center gap-6 grid lg:grid-cols-2 grid-cols-1">
            <div class="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
              <div class="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                <img
                  class=" rounded-xl object-cover bg-gray-200 py-16"
                  src="./bot125.png"
                  alt="about Us image"
                />
              </div>
              <img
                class="sm:ml-0 ml-auto rounded-xl object-cover bg-gray-200 py-16"
                src="./bot2.png"
                alt="about Us image"
              />
            </div>
            <div class="w-full flex-col justify-center lg:items-start items-center gap-2 inline-flex">
              <div class="w-full flex-col justify-center items-start gap-2 flex">
                <div class="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                  <h2 class="text-green-500 text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                    About <span className="text-[#dcc119]">Us</span>
                  </h2>
                  <p class="text-gray-800 text-sm font-normal  leading-relaxed lg:text-justify text-center">
                    Introducing JastroFX (JFX)—an innovative trading platform
                    and broker house from thailand, Developed by a group of
                    seasoned trading professionals. After years of expertise,
                    we’ve built a platform registered in Thailand that combines
                    cutting-edge technology with unparalleled market insight.
                  </p>
                </div>
                <div class="w-full mt-2">
                  <div class="flex-col justify-start items-start inline-flex">
                    <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-red-800 p-[1.5px]">
                      <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#dc2626_20deg,transparent_120deg)]"></div>
                      <div class="relative z-20 flex w-full rounded-[0.60rem] bg-slate-100 ">
                        <div className="bg-gray-200 rounded-lg shadow-lg  text-center h-full flex flex-col justify-between">
                          <div>
                            <p className="text-red-600 text-xs md:text-xs text-justify p-2">
                              Our mission is to provide a secure and transparent
                              trading platform that offers cutting-edge
                              technology, expert insights, and profitable
                              opportunities. We are dedicated to fostering
                              financial growth for our investors by delivering
                              exceptional value, education, and support at every
                              step of their Forex journey
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start inline-flex mt-2">
                  <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-green-800 p-[1.5px]">
                      <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#166534_20deg,transparent_120deg)]"></div>
                      <div class="relative z-20 flex w-full rounded-[0.60rem] bg-slate-200 ">
                        <div className="bg-gray-200 rounded-lg shadow-lg  text-center h-full flex flex-col justify-between">
                          <div>
                            <p className="text-green-600 text-xs md:text-xs text-justify p-2">
                              Our mission is to provide a secure and transparent
                              trading platform that offers cutting-edge
                              technology, expert insights, and profitable
                              opportunities. We are dedicated to fostering
                              financial growth for our investors by delivering
                              exceptional value, education, and support at every
                              step of their Forex journey
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
