// import { sendOTP, verifyOTP ,clearOtpErrors,clearOtpMessage} from "../redux/otpSlice"; // Assume you have actions for OTP handling
// import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import Spinner from "../BaseFile/comman/Spinner";
// import { getUser } from "../redux/userSlice";
// import {
//   addWithdrawal,
//   clearErrors,
//   clearMessage,
// } from "../redux/withdrawalSlice";

// export default function UserWithdrawalModel({ openModel, modelClose, action }) {
//   const dispatch = useDispatch();
//   const { auth } = useSelector((state) => state.auth);

//   const {loading, success ,error:otpErr,message} = useSelector((state) => state.otp);
//   const { singleuser } = useSelector((state) => state.allusers);
//   const [values, setValues] = useState({});
//   const [isOTPRequested, setIsOTPRequested] = useState(false);
//   const [otp, setOtp] = useState("");
//   const [check, setCheck] = useState(null);
//   const [maxAmount, setMaxAmount] = useState(null);
//   useEffect(() => {
//     dispatch(getUser(auth?.id));

//     if (otpErr) {
//       const errorInterval = setInterval(() => {
//         dispatch(clearOtpErrors());
//       }, 3000);
//       return () => clearInterval(errorInterval);
//     }

//     if (success) {
//       const messageInterval = setInterval(() => {
//         dispatch(clearOtpMessage());
//       }, 3000);
//       return () => clearInterval(messageInterval);
//     }
//   }, [dispatch,otpErr,success]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setValues((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleOTPChange = (e) => {
//     setOtp(e.target.value);
//   };

//   const requestOTP = async () => {
//     if(values.amount > maxAmount){
//      return window.alert("Amount exceed Maximum Amount")
//     }
//     if(values.amount < 25){
//       return window.alert("Amount should be greater than 24")
//      }
//     dispatch(sendOTP({ userId: auth?.id, email: singleuser?.email }));
//     setIsOTPRequested(true);
//     verifyAndSubmit()
//   };

//   const verifyAndSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await dispatch(verifyOTP({ userId: auth?.id, otp }));
//       if (response.type) {
//         console.log("Verification succeeded");
//         const allValues = {
//           ...values,
//           user_id: auth?.id,
//           check: check,
//           action:action
//         };
//         await dispatch(addWithdrawal({ values: allValues }));
//         modelClose();
//       } else {
//         console.log("Verification failed");
//       }
//     } catch (error) {
//       console.error("An error occurred during verification:", error);
//     }
//   };

//   useEffect(() => {
//     const activationDate = new Date(singleuser?.activation_date); // Parse the activation date
//     const currentDate = new Date();
//     const sixMonthsLater = new Date(
//       activationDate.getFullYear(),
//       activationDate.getMonth() + 6,
//       activationDate.getDate()
//     );
//     if (currentDate >= sixMonthsLater) {
//       setCheck(true);
//       setMaxAmount(
//         singleuser?.wallet - singleuser?.roi_income + singleuser?.active_plan
//       );
//     } else {
//       setCheck(false);
//       setMaxAmount(
//         singleuser?.wallet - singleuser?.roi_income - singleuser?.business
//       );
//     }
//   }, [singleuser?.activation_date]);

//   return (
//     <Dialog open={openModel} onClose={modelClose} className="relative z-50">
//       <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
//       <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
//         <div className="flex min-h-full items-center justify-center p-4">
//           <DialogPanel className="relative transform overflow-hidden rounded-lg bg-black px-4 pb-4 pt-5 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6">
//             <div>
//               <div className="p-5 ">
//                 <div className="flex justify-between mb-3">
//                   <h2 className="text-xl font-semibold mb-5 text-gray-300">
//                     {check ? (
//                       <>
//                         <span className="block"> Withdrawal Form</span>{" "}
//                         <span className="text-xs">
//                           {" "}
//                           (Your Wallet:{" "}
//                           {(maxAmount)?.toFixed(2)}
//                           )
//                         </span>
//                       </>
//                     ) : (
//                       <>
//                         <span className="block"> Withdrawal Form</span>{" "}
//                         <span className="text-xs">
//                           {" "}
//                           (Your Wallet:{" "}
//                           {(maxAmount)?.toFixed(2)}
//                           )
//                         </span>
//                       </>
//                     )}
//                   </h2>
//                   <button onClick={modelClose}>
//                     <div class="group flex  cursor-pointer items-center justify-center mb-2 ">
//                       <div class="space-y-2">
//                         <span class="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
//                         <span class="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
//                       </div>
//                     </div>
//                   </button>
//                 </div>
//                 <form>
//                   {!isOTPRequested ? (
//                     <>
//                       <div className="relative w-full">
//                         <label
//                           htmlFor="amount"
//                           className="absolute -top-2 left-2 bg-black px-1 text-xs font-medium text-gray-300"
//                         >
//                           Amount
//                         </label>
//                         <input
//                           id="amount"
//                           type="number"
//                           name="amount"
//                           placeholder="Amount"
//                           onChange={handleChange}
//                           required
//                           min="25"
//                           max={maxAmount}
//                           className="block w-full p-4 rounded-md border-0 text-gray-300 bg-black shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600"
//                         />
//                       </div>
//                       <p className="text-gray-200 text-xs mt-2">
//                         Minimum withdrawal: $25. Admin fee: 05%. Processing
//                         time: 72 hours.
//                       </p>
//                       <button
//                         type="button"
//                         disabled={values.amount ? false : true}
//                         className="w-full mt-4 p-2 bg-gray-800 hover:bg-gray-900 text-white rounded"
//                         onClick={requestOTP}
//                       >
//                         Request OTP
//                       </button>
//                     </>
//                   ) : (
//                     <>
//                       <div className="relative w-full">
//                         <label
//                           htmlFor="otp"
//                           className="absolute -top-2 left-2 bg-black px-1 text-xs font-medium text-gray-300"
//                         >
//                           OTP
//                         </label>
//                         <input
//                           id="otp"
//                           type="text"
//                           name="otp"
//                           placeholder="Enter OTP"
//                           value={otp}
//                           onChange={handleOTPChange}
//                           required
//                           className="block w-full p-4 rounded-md border-0 text-gray-300 bg-black shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600"
//                         />
//                       </div>
//                       <button
//                         type="submit"
//                         disabled={otp.length==6 ? false : true }
//                         className="w-full mt-4 p-2 bg-gray-800 hover:bg-gray-900 text-white rounded "
//                         onClick={(e)=>verifyAndSubmit(e,"withdrawal")}
//                       >
//                         {loading ? <Spinner /> : "Submit Withdrawal"}
//                       </button>
//                       <button
//                         type="submit"
//                         disabled={otp.length==6 ? false : true }
//                         className="w-full mt-4 p-2 bg-gray-800 hover:bg-gray-900 text-white rounded "
//                         onClick={(e)=>verifyAndSubmit(e,"transfer")}
//                       >
//                         {loading ? <Spinner /> : "Transfer to active wallet"}
//                       </button>

//                     </>
//                   )}
//                 </form>
//                 <p className="text-red-600 text-2xl">{otpErr}</p>
//               </div>
//             </div>
//           </DialogPanel>
//         </div>
//       </div>
//     </Dialog>
//   );
// }

import {
  sendOTP,
  verifyOTP,
  clearOtpErrors,
  clearOtpMessage,
} from "../redux/otpSlice";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../BaseFile/comman/Spinner";
import { getUser } from "../redux/userSlice";
import {
  addWithdrawal,
  clearErrors,
  clearMessage,
} from "../redux/withdrawalSlice";

export default function UserWithdrawalModel({ openModel, modelClose, action }) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  const {
    loading,
    success,
    error: otpErr,
    message,
  } = useSelector((state) => state.otp);
  const { singleuser } = useSelector((state) => state.allusers);
  const [values, setValues] = useState({});
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [otp, setOtp] = useState("");
  const [check, setCheck] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountMaxAmount, setAccountMaxAmount] = useState(0);

  useEffect(() => {
    dispatch(getUser(auth?.id));

    if (otpErr) {
      const errorInterval = setInterval(() => {
        dispatch(clearOtpErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (success) {
      const messageInterval = setInterval(() => {
        dispatch(clearOtpMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, otpErr, success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const handleAccountChange = (accountType) => {
    setSelectedAccount(accountType);

    // Set the maximum amount based on the selected account
    if (accountType === "wallet") {
      setAccountMaxAmount(singleuser?.business || 0);
    } else if (accountType === "roi") {
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const pastDays = Math.ceil(
        (currentDate - startOfMonth) / (1000 * 60 * 60 * 24)
      );

      const roiDayIncome = singleuser?.roi_day * pastDays || 0;
      let adjustedIncome = singleuser?.roi_income - roiDayIncome;

      setMaxAmount(adjustedIncome > 0 ? adjustedIncome : 0);
    }
  };

  const requestOTP = async () => {
    
    if (values.amount > maxAmount) {
      return window.alert("Amount exceeds Maximum Amount");
    }
    if (values.amount < 25) {
      return window.alert("Amount should be greater than 24");
    }
    dispatch(sendOTP({ userId: auth?.id, email: singleuser?.email }));
    setIsOTPRequested(true);
  };

  const verifyAndSubmit = async (e, actionType) => {
    e.preventDefault();
    try {
      const response = await dispatch(verifyOTP({ userId: auth?.id, otp }));
      if (response?.payload?.success) {
        const allValues = {
          ...values,
          user_id: auth?.id,
          check: check,
          action: actionType,
        };

        await dispatch(addWithdrawal({ values: allValues }));
        modelClose();
      } else {
        console.log("Verification failed");
      }
    } catch (error) {
      console.error("Verification failed or an error occurred:", error);
    }
  };

  useEffect(() => {
    const activationDate = new Date(singleuser?.activation_date);
    const currentDate = new Date();
    const sixMonthsLater = new Date(
      activationDate.getFullYear(),
      activationDate.getMonth() + 6,
      activationDate.getDate()
    );
    if (currentDate >= sixMonthsLater) {
      setCheck(true);
      setMaxAmount(
        singleuser?.wallet - singleuser?.roi_income + singleuser?.active_plan
      );
    } else {
      setCheck(false);
      setMaxAmount(
        singleuser?.wallet - singleuser?.roi_income - singleuser?.business
      );
    }
  }, [singleuser?.activation_date]);

  return (
    <Dialog open={openModel} onClose={modelClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-black px-4 pb-4 pt-5 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div className="p-5 ">
                <div className="flex justify-between mb-3">
                  <h2 className="text-xl font-semibold mb-5 text-gray-300">
                    {action === "withdrawal"
                      ? "Withdrawal Form"
                      : "Transfer Form"}
                    <span className="text-xs block">
                      (Your Wallet: ${maxAmount?.toFixed(2)})
                    </span>
                  </h2>
                  <button onClick={modelClose}>
                    <div className="group flex cursor-pointer items-center justify-center mb-2 ">
                      <div className="space-y-2">
                        <span className="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
                        <span className="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
                      </div>
                    </div>
                  </button>
                </div>
                <form>
                  {!isOTPRequested ? (
                    <>
                      <div className="relative w-full mb-4">
                        <label
                          htmlFor="amount"
                          className="absolute -top-2 left-2 bg-black px-1 text-xs font-medium text-gray-300"
                        >
                          Amount
                        </label>
                        <input
                          id="amount"
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          onChange={handleChange}
                          required
                          min="25"
                          max={
                            action === "withdrawal"
                              ? maxAmount
                              : accountMaxAmount
                          }
                          className="block w-full p-4 rounded-md border-0 text-gray-300 bg-black shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                        />
                      </div>

                      <p className="text-gray-200 text-xs mt-2">
                        {action === "withdrawal" ? (
                          <>
                            Minimum withdrawal: $25. Admin fee: 05%. Processing
                            time: 72 hours.
                          </>
                        ) : (
                          <>
                            Transfer funds from your selected account to your
                            active wallet. No fees applied.
                          </>
                        )}
                      </p>
                      <button
                        type="button"
                        className={`w-full mt-4 p-2 transition-transform duration-300 ease-in-out ${
                          !values.amount || action === "transfer"
                            ? "bg-indigo-600 hover:scale-110"
                            : "bg-gray-800 hover:bg-gray-900"
                        } text-white rounded hover:scale-105`}
                        onClick={requestOTP}
                      >
                        Request OTP
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="relative w-full">
                        <label
                          htmlFor="otp"
                          className="absolute -top-2 left-2 bg-black px-1 text-xs font-medium text-gray-300"
                        >
                          OTP
                        </label>
                        <input
                          id="otp"
                          type="text"
                          name="otp"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={handleOTPChange}
                          required
                          className="block w-full p-4 rounded-md border-0 text-gray-300 bg-black shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                        />
                      </div>

                      {action === "withdrawal" ? (
                        <button
                          type="submit"
                          disabled={otp.length !== 6}
                          className={`w-full mt-4 p-2 ${
                            otp.length !== 6
                              ? "bg-gray-600"
                              : "bg-gray-800 hover:bg-gray-900"
                          } text-white rounded`}
                          onClick={(e) => verifyAndSubmit(e, "withdrawal")}
                        >
                          {loading ? <Spinner /> : "Submit Withdrawal"}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={otp.length !== 6}
                          className={`w-full mt-4 p-2 ${
                            otp.length !== 6
                              ? "bg-gray-600"
                              : "bg-gray-800 hover:bg-gray-900"
                          } text-white rounded`}
                          onClick={(e) => verifyAndSubmit(e, "transfer")}
                        >
                          {loading ? (
                            <Spinner />
                          ) : (
                            `Verify and Transfer`
                          )}
                        </button>
                      )}
                    </>
                  )}
                </form>
                {otpErr && (
                  <p className="text-red-600 text-2xl mt-2">{otpErr}</p>
                )}
                {message && (
                  <p className="text-green-500 text-lg mt-2">{message}</p>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
