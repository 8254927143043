import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Wallet, 
    PlusCircle, 
    MinusCircle, 
    Calendar, 
    Percent, 
    DollarSign 
} from 'lucide-react';
import { fetchWalletDetails, depositFunds, withdrawFunds } from '../redux/wallet';

const WalletDetailsCard = () => {
    const dispatch = useDispatch();
     const { wallet, loading } = useSelector((state) => state.wallet);
     const { auth } = useSelector((state) => state.auth);
     const userID = auth?.id ?? "";
    const [depositModal, setDepositModal] = useState(false);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
      useEffect(() => {
        if (userID) {
          dispatch(fetchWalletDetails(userID));
        }
      }, [dispatch, userID]);

    const handleDeposit = () => {
        if (!termsAccepted) {
            alert('Please accept the terms and conditions');
            return;
        }
        
        dispatch(depositFunds({
            WalletID: wallet.WalletID,
            Amount: parseFloat(amount)
        }));
        
        setDepositModal(false);
        setAmount('');
        setTermsAccepted(false);
    };

    const handleWithdraw = () => {
        if (!termsAccepted) {
            alert('Please accept the terms and conditions');
            return;
        }
        
        dispatch(withdrawFunds({
            WalletID: wallet.WalletID,
            Amount: parseFloat(amount)
        }));
        
        setWithdrawModal(false);
        setAmount('');
        setTermsAccepted(false);
    };


    const TransactionModal = ({ 
        isOpen, 
        onClose, 
        title, 
        actionText, 
        onAction, 
        modalType 
    }) => (
        isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-md w-full">
                    <h2 className="text-xl font-bold mb-4 text-gray-800">{title}</h2>
                    <div className="mb-4">
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                            Amount
                        </label>
                        <div className="relative mt-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <DollarSign className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="number"
                                id="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Enter amount"
                                min="1"
                                step="0.01"
                            />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <button 
                            onClick={onClose} 
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={() => {
                                setDepositModal(false);
                                setWithdrawModal(false)
                            }}
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                        >
                            Show Terms
                        </button>
                    </div>
                </div>
            </div>
        )
    );

    return (
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-xl p-6 space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                    <Wallet className="h-8 w-8 text-blue-600" />
                    <h2 className="text-xl font-bold text-gray-800">
                        Wallet Details
                    </h2>
                </div>
                <div className="text-sm text-gray-500 flex items-center space-x-2">
                    <Calendar className="h-4 w-4" />
                    <span>
                        {new Date(wallet?.LastUpdatedDate).toLocaleDateString()}
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-100 p-4 rounded-lg">
                    <div className="flex items-center space-x-2 mb-2">
                        <DollarSign className="h-5 w-5 text-green-600" />
                        <span className="text-sm font-medium text-gray-600">
                            Current Balance
                        </span>
                    </div>
                    <p className="text-2xl font-bold text-gray-800">
                        ${parseFloat(wallet?.CurrentCompoundAmount).toFixed(2)}
                    </p>
                </div>
                <div className="bg-gray-100 p-4 rounded-lg">
                    <div className="flex items-center space-x-2 mb-2">
                        <Percent className="h-5 w-5 text-blue-600" />
                        <span className="text-sm font-medium text-gray-600">
                            Monthly Interest
                        </span>
                    </div>
                    <p className="text-2xl font-bold text-gray-800">
                        {parseFloat(wallet?.MonthlyInterestRate).toFixed(2)}%
                    </p>
                </div>
            </div>

            <div className="flex space-x-4">
                <button
                    onClick={() => setDepositModal(true)}
                    className="flex-1 flex items-center justify-center space-x-2 bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition"
                >
                    <PlusCircle className="h-5 w-5" />
                    <span>Deposit</span>
                </button>
                <button
                    onClick={() => setWithdrawModal(true)}
                    className="flex-1 flex items-center justify-center space-x-2 bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition"
                >
                    <MinusCircle className="h-5 w-5" />
                    <span>Withdraw</span>
                </button>
            </div>

            {/* Deposit Modal */}
            <TransactionModal
                isOpen={depositModal}
                onClose={() => setDepositModal(false)}
                title="Deposit Funds"
                actionText="Deposit"
                modalType="deposit"
            />

            {/* Withdrawal Modal */}
            <TransactionModal
                isOpen={withdrawModal}
                onClose={() => setWithdrawModal(false)}
                title="Withdraw Funds"
                actionText="Withdraw"
                modalType="withdraw"
            />

           
        </div>
    );
};

export default WalletDetailsCard;