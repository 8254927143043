import { useState, useEffect } from "react";

export default function HeroSection() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setImageLoaded(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="bg-[#f3f3f3] ">      
        <div className="w-7xl mx-auto flex flex-col lg:gap-5 lg:flex-row items-center bg-[#f3f3f3] text-black px-4 pt-24 pb-16 lg:pt-28 lg:px-12 rounded-lg shadow-lg">
          <div className="lg:w-2/5  w-full mb-6 lg:mb-0 text-center lg:text-left">
            <h1 className="lg:text-4xl text-xl text-red-500  lg:block flex items-center gap-2 justify-start text-justify  font-bold lg:mb-4 mb-2 leading-tight">
            Trade Smartly,
              <span className=" text-green-600  block lg:mt-2">
              Invest Wisely with JFX
              </span>
            </h1>
            <p className="text-sm lg:text-base text-gray-800  text-justify lg:mb-0 mb-6">
            Welcome to JastroFX, your trusted platform for smart and simple trading. Whether you're a beginner or an expert, we make trading easy and secure for everyone.
            </p>
            <p className="text-sm lg:text-base text-gray-800  text-justify lg:mb-0 mt-4">
            Discover a world of opportunities with advanced tools and real-time updates. Start your trading journey today and achieve your financial goals with confidence.
            </p>
          </div>
          <div className="lg:w-3/5 w-full ">
            <div className="relative p-4 ">
              {/* <div
                className="absolute inset-0 bg-cover bg-center rounded-l-full  border-2 border-r-0 border-green-300 "
                style={{ backgroundImage: `url('/tradingwallpaper.jpg')` }}
              ></div> */}
              <img
                src="https://img.freepik.com/free-photo/3d-delivery-robot-working_23-2151150213.jpg?uid=R176823449&ga=GA1.1.1433286368.1718702777&semt=ais_hybrid"
                alt="AI Agent"
                className="relative z-20 w-full h-auto max-w-sm lg:max-w-2xl object-contain rounded-l-full shadow-lg  ml-auto "
              />
            </div>
          </div>
        </div>    
      </div>
    </>
  );
}
