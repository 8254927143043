import React from "react";
import { Link } from "react-router-dom";

const pricingPlans = [
  {
    title: "Bronze Package",
    price: "100 - 2999",
    features:
      "Basic access to the platform, limited trading capabilities, essential support. Ideal for beginners or casual traders.",
    buttonColor: "bg-gray-500",
  },
  {
    title: "Silver Package",
    price: "3000 - 9,999",
    features:
      "Advanced trading tools, increased asset selection, priority support. Perfect for intermediate traders looking to expand their portfolio.",
    buttonColor: "bg-blue-600",
  },
  {
    title: "Gold Package",
    price: "10,000 - 19,999  ",
    features:
      "Premium features, exclusive insights, dedicated account manager. Designed for experienced traders seeking maximum potential.",
    buttonColor: "bg-gray-500",
  },
  {
    title: "Platinum Package",
    price: "20,000 Above",
    features:
      "Premium features, exclusive insights, dedicated account manager. Designed for experienced traders seeking maximum potential.",
    buttonColor: "bg-gray-500",
  },
];

export const Pricing = () => {
  return (
    <>
      <div className="bg-[#f3f3f3]">
        <div className="mx-auto max-w-7xl px-5 py-10 lg:pb-16 ">
          <div className="md:flex  md:flex-col px-5">
            <div className="w-full flex-grow text-left lg-text-center">
              <h1
                id="pricing"
                className="mb-3 text-2xl lg:text-3xl sm:text-3xl font-bold text-green-500"
              >
                Pricing
              </h1>
              <h3 className="text-sm mb-5 font-medium text-gray-800">
                Uncover the power of Bright future through our flexible pricing
                plans designed to meet your needs.
              </h3>
            </div>
          </div>
          {/* <div className="grid grid-cols-4"> */}
            <div className=" relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-slate-800 p-[1.5px]">
              <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#0ea5e9_20deg,transparent_120deg)]"></div>
              <div class="relative z-20 flex w-full rounded-[0.60rem] bg-slate-200 p-5">
                <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-4 ">
                  {pricingPlans.map((plan, index) => (
                    <div
                      key={index}
                      className="mx-auto w-full rounded-md bg-white px-8 py-8 shadow-lg shadow-blue-100  border-transparent transition-all duration-300 hover:shadow-2xl hover:shadow-blue-200 hover:border-[#169dbf] border-2 hover:scale-105 hover:animate-pulse md:max-w-none"
                    >
                      <div className="w-full flex-grow">
                        <h2 className="mb-4 text-center font-bold capitalize text-xl text-red-800">
                          {plan.title}
                        </h2>
                        <h3 className="mb-5 text-center text-2xl font-bold text-green-500">
                          ${plan.price}
                          {/* <span className="text-lg">/month</span> */}
                        </h3>
                        <div>
                          {/* {plan.features.map((feature, idx) => (
                            <p key={idx} className="text-sm mb-2">
                              {feature}
                            </p>
                          ))} */}
                          <p className="text-sm mb-5 text-justify">
                            {plan.features}
                          </p>
                        </div>
                      </div>
                      <div className="w-full text-center">
                        <Link
                          to="user/login"
                          className={`w-full whitespace-nowrap rounded-full bg-green-500 px-8 py-2 font-bold text-white transition-colors hover:bg-green-500`}
                        >
                          Join Now
                        </Link>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
