import React from 'react';

const LoaderPopup = ({ isLoading, text = "Loading..." }) => {
  if (!isLoading) return null;
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop overlay */}
      <div className="absolute inset-0 bg-white bg-opacity-80 backdrop-blur-sm"></div>
      
      {/* Loader container */}
      <div className="relative z-10 bg-white rounded-2xl shadow-xl p-8 max-w-sm w-full mx-4 flex flex-col items-center">
        {/* Spinner animation */}
        <div className="relative w-20 h-20 mb-6">
          {/* Outer spinning circle */}
          <div className="absolute inset-0 rounded-full border-t-4 border-blue-500 animate-spin"></div>
          
          {/* Inner pulsing circle */}
          <div className="absolute inset-2 rounded-full border-2 border-blue-200 flex items-center justify-center animate-pulse">
            <div className="w-2 h-2 bg-blue-600 rounded-full"></div>
          </div>
          
          {/* Multiple dots orbiting */}
          {/* <div className="h-full w-full flex items-center justify-center">
            {[...Array(6)].map((_, i) => (
              <div 
                key={i} 
                className="absolute w-2 h-2 bg-blue-500 rounded-full"
                style={{
                  animation: `orbit 1.5s linear infinite`,
                  animationDelay: `${i * 0.25}s`,
                  transformOrigin: 'center',
                  left: '50%',
                  top: '0',
                  marginLeft: '-1px'
                }}
              ></div>
            ))}
          </div> */}
        </div>
        
        {/* Loading text */}
        <h3 className="text-gray-800 font-medium text-lg mb-2">{text}</h3>
        <p className="text-gray-500 text-sm text-center">Please wait while we process your request...</p>
        
        {/* Progress bar */}
        <div className="w-full h-1 bg-gray-100 rounded-full mt-4 overflow-hidden">
          <div className="h-full bg-blue-500 rounded-full animate-progress"></div>
        </div>
      </div>
    </div>
  );
};

// Add these keyframes to your global CSS or use a CSS-in-JS solution
const styleTag = document.createElement('style');
styleTag.innerHTML = `
  @keyframes orbit {
    0% {
      transform: rotate(0deg) translateX(30px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(30px) rotate(-360deg);
    }
  }
  
  @keyframes progress {
    0% { width: 0%; }
    50% { width: 70%; }
    100% { width: 100%; }
  }
  
  .animate-progress {
    animation: progress 2s ease-in-out infinite;
  }
`;
document.head.appendChild(styleTag);

export default LoaderPopup;