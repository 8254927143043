import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { useParams } from "react-router-dom";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { RiLoginCircleFill } from "react-icons/ri";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Loader from "../BaseFile/comman/Loader";
import { FaArrowAltCircleUp, FaArrowCircleDown } from "react-icons/fa";
import { loginUser } from "../redux/authSlice";
import { getAllUsers, deleteUsers } from "../redux/userSlice";
import { clearErrors, clearMessage } from "../redux/withdrawalSlice";
import AdminCashHandle from "./AdminCashHandle";
import { useDispatch, useSelector } from "react-redux";

export default function AdminUserList() {
  const { action } = useParams();
  const dispatch = useDispatch();
  const { allusers, loading } = useSelector((state) => state.allusers);
  const { message, error } = useSelector((state) => state.allwithdrawal);
  const [allUser, setAllUser] = useState(allusers);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPlanQuery, setSearchPlanQuery] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const [cashHandle, setCashHandle] = useState(null);
  const [userId, setUserId] = useState();
  const [name, setName] = useState(null);
  const [balance, setBalance] = useState(null);
  const [roi, setROI] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [paginatedUsers, setPaginatedUser] = useState([])
  const [totalPages, setTotalPages] = useState(null)


  useEffect(() => {
    dispatch(getAllUsers());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  useEffect(() => {
    if (action === "all") {
      setAllUser(allusers);
    } else {
      setAllUser(
        allusers?.filter((p) => p.is_active === action || p.status === action)
      );
    }
  }, [action, allusers]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  
  useEffect(() => {
    const filteredUsers = allUser
      ?.filter((item) => item.role !== "admin")
      ?.filter(
        (item) =>
          item.email.toLowerCase().includes(searchQuery.toLowerCase()) || // Ensure case-insensitive matching
          item.username.toLowerCase().includes(searchQuery.toLowerCase()) || // Ensure case-insensitive matching
          item.refferal_code.toLowerCase().includes(searchQuery.toLowerCase()) || // Ensure case-insensitive matching
          item.active_plan >= searchQuery // Ensure strict equality for active_plan
      );
  
    // Calculate the total number of pages
    const calculatedTotalPages = Math.ceil(filteredUsers?.length / itemsPerPage);
    setTotalPages(calculatedTotalPages);
  
    // Slice the filtered users for pagination based on the currentPage
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedUsers = filteredUsers?.slice(startIndex, startIndex + itemsPerPage);
    setPaginatedUser(paginatedUsers);
  }, [searchQuery, allUser, currentPage, itemsPerPage]); // Correct dependencies
  

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchQuery(searchTerm);
  };

  const handleDelete = (id) => {
    setdeleteID(id);
    setModalopen(true);
  };

  const handleCash = (action, item) => {
    setCashHandle(action);
    setName(item?.username);
    setUserId(item?.id);
    setBalance(item?.business + item?.investment_month + item?.reward + item?.direct_income);
    setROI(item?.roi_income);
  };

  const handleSession = (item) => {
    const values = { email: item.email, password: item.password };
    dispatch(loginUser(values));
    window.open("/user/dashboard", "_blank");
  };

  const handlePagination = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="bg-gray-900">
      <div className="pt-5">
        <label htmlFor="email" className="sr-only">
          Search using email
        </label>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={handleSearch}
          type="text"
          placeholder="search here . . ."
          className="block w-full md:w-[50vh] px-2 py-1 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
     
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}

      <div className={` ${loading ? "h-[560px] items-center" : "h-full"}`}>
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <div className="mt-4 flow-root">
            <div className="overflow-x-auto">
              <div className="py-2">
                <table className="divide-y divide-gray-700 w-full border border-gray-700">
                  <thead className="border-b border-white/10 text-sm leading-6 text-white">
                    <tr>
                      <th className="px-2 ml-4 text-left text-white border border-gray-700">
                        S.No
                      </th>
                      <th className="px-2 ml-4 text-left text-white border border-gray-700">
                        E-mail
                      </th>
                      <th className="px-2 py-4 text-white border border-gray-700 text-center">
                        Wallet
                      </th>
                      <th className="px-2 py-4 text-white border border-gray-700 text-center">
                        A-Wallet
                      </th>
                      <th className="px-2 py-4 text-center text-white border border-gray-700">
                        Account Status
                      </th>
                      <th className="px-2 py-4 text-center text-white border border-gray-700">
                        Package Status
                      </th>
                      <th className="px-2 py-4 text-center text-white border border-gray-700">
                        Created at
                      </th>
                      <th className="px-2 py-4 text-center text-white border border-gray-700">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-white/5">
                    {paginatedUsers?.map((item, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-700 transition-colors duration-200"
                      >
                         <td className="px-4 py-4 text-center text-white border border-gray-700">
                          <div className="text-sm text-gray-400">
                            {index+1}
                          </div>
                        </td>
                        <td className="px-4 py-4 text-left text-white border border-gray-700">
                          <div className="flex items-center justify-center gap-x-4">
                            <div className="truncate text-sm font-medium leading-6 text-white w-full">
                              {item?.email}
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.refferal_code || ""
                                  );
                                  alert("Copied to clipboard!");
                                }}
                                className="focus:outline-none text-white bg-indigo-600 ml-1 px-4 py-[2px] rounded-full text-[10px]"
                              >
                                {item?.refferal_code}
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-white border border-gray-700">
                          <div className="flex justify-between">
                            <button
                              onClick={() =>
                                handleCash(
                                  "credit",
                                  item
                                )
                              }
                              className="bg-green-600 text-white p-2 rounded transition-all duration-200 hover:bg-green-500"
                            >
                              <FaArrowAltCircleUp />
                            </button>
                            <div>${item?.business}</div>
                            <button
                              onClick={() =>
                                handleCash(
                                  "debit",
                                  item
                                )
                              }
                              className="bg-red-600 text-white p-2 rounded transition-all duration-200 hover:bg-red-500"
                            >
                              <FaArrowCircleDown />
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-center text-white border border-gray-700">
                          <div className="text-sm text-gray-400">
                            {item?.active_plan}
                          </div>
                        </td>
                        <td className="px-4 py-4 text-center text-white border border-gray-700">
                          {item?.status === "block" ? (
                            <div className="flex items-center text-white gap-2">
                              <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
                              Blocked
                            </div>
                          ) : (
                            <div className="flex items-center text-white gap-2">
                              <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
                              Unblocked
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-4 text-center text-white border border-gray-700">
                          {item?.is_active === "active" ? (
                            <div className="flex items-center text-white gap-2">
                              <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
                              Activated
                            </div>
                          ) : (
                            <div className="flex items-center text-white gap-2">
                              <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
                              Not Activated
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-4 text-center text-white border border-gray-700">
                          <div className="text-sm text-gray-400">
                            {new Date(item?.created_at).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-4 py-4 text-center text-white border border-gray-700">
                          <div className="flex justify-center items-center space-x-2">
                            <button onClick={() => handleSession(item)}>
                              <RiLoginCircleFill />
                            </button>
                            <Link to={`/admin/check/profile/${item?.id}`}>
                              <GrView />
                            </Link>
                            {/* <button onClick={() => handleDelete(item?.id)}>
                              <AiFillDelete />
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {modalopen && (
        <Confirmation
          isClose={() => setModalopen(false)}
          deletefunction={deleteUsers}
          id={deleteID}
        />
      )}
      {cashHandle && (
        <AdminCashHandle
          HandleCashmodel={() => setCashHandle(null)}
          cashHandle={cashHandle}
          userId={userId}
          name={name}
          balance={balance}
          roi={roi}
        />
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between py-4 mx-8">
        <button
          onClick={() => handlePagination("prev")}
          disabled={currentPage === 1}
          className="text-white bg-indigo-600 px-4 py-2 rounded"
        >
          Previous
        </button>
        <div className="text-white">
          Page {currentPage} of {totalPages}
        </div>
        <button
          onClick={() => handlePagination("next")}
          disabled={currentPage === totalPages}
          className="text-white bg-indigo-600 px-4 py-2 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
}





// import { Link } from "react-router-dom";
// import { useState, useEffect } from "react";
// import { AiFillDelete } from "react-icons/ai";
// import { GrView } from "react-icons/gr";
// import { useParams } from "react-router-dom";
// import SuccessAlert from "../BaseFile/comman/SuccessAlert";
// import ErrorAlert from "../BaseFile/comman/ErrorAlert";
// import { RiLoginCircleFill } from "react-icons/ri";
// import { Confirmation } from "../BaseFile/comman/Confirmation";
// import Loader from "../BaseFile/comman/Loader";
// import { FaArrowAltCircleUp, FaArrowCircleDown, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
// import { loginUser } from "../redux/authSlice";
// import { getAllUsers, deleteUsers } from "../redux/userSlice";
// import { clearErrors, clearMessage } from "../redux/withdrawalSlice";
// import AdminCashHandle from "./AdminCashHandle";
// import { useDispatch, useSelector } from "react-redux";

// export default function AdminUserList() {
//   const { action } = useParams();
//   const dispatch = useDispatch();
//   const { allusers, pagination, loading } = useSelector((state) => state.allusers);
//   const { message, error } = useSelector((state) => state.allwithdrawal);
  
//   // State for filtering and sorting
//   const [searchQuery, setSearchQuery] = useState("");
//   const [sortField, setSortField] = useState("id");
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
  
//   // State for UI operations
//   const [modalopen, setModalopen] = useState(false);
//   const [deleteID, setdeleteID] = useState();
//   const [cashHandle, setCashHandle] = useState(null);
//   const [userId, setUserId] = useState();
//   const [name, setName] = useState(null);
//   const [balance, setBalance] = useState(null);
//   const [roi, setROI] = useState(null);

//   // Fetch users with current filters on component mount and when filters change
//   useEffect(() => {
//     const params = {
//       page: currentPage,
//       limit: itemsPerPage,
//       sort: sortField,
//       order: sortOrder
//     };
    
//     // Add search filter if present
//     if (searchQuery) {
//       params.email = searchQuery;
//       params.username = searchQuery;
//     }
    
//     dispatch(getAllUsers(params));
//   }, [dispatch, currentPage, itemsPerPage, sortField, sortOrder, searchQuery]);

//   // Handle error and message cleanup
//   useEffect(() => {
//     if (error) {
//       const errorInterval = setInterval(() => {
//         dispatch(clearErrors());
//       }, 3000);
//       return () => clearInterval(errorInterval);
//     }
//     if (message) {
//       const messageInterval = setInterval(() => {
//         dispatch(clearMessage());
//       }, 3000);
//       return () => clearInterval(messageInterval);
//     }
//   }, [dispatch, error, message]);

//   // Reset to first page when search changes
//   useEffect(() => {
//     setCurrentPage(1);
//   }, [searchQuery]);

//   // Handle search input
//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   // Handle sort toggle
//   const handleSort = (field) => {
//     if (sortField === field) {
//       // Toggle order if same field
//       setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//     } else {
//       // Set new field and default to ascending
//       setSortField(field);
//       setSortOrder("asc");
//     }
//     setCurrentPage(1); // Reset to first page
//   };

//   // Get sort icon based on current sort state
//   const getSortIcon = (field) => {
//     if (sortField !== field) return <FaSort className="inline ml-1" />;
//     return sortOrder === "asc" ? <FaSortUp className="inline ml-1" /> : <FaSortDown className="inline ml-1" />;
//   };

//   const handleDelete = (id) => {
//     setdeleteID(id);
//     setModalopen(true);
//   };

//   const handleCash = (action, item) => {
//     setCashHandle(action);
//     setName(item?.username);
//     setUserId(item?.id);
//     setBalance(item?.business + item?.investment_month + item?.reward + item?.direct_income);
//     setROI(item?.roi_income);
//   };

//   const handleSession = (item) => {
//     const values = { email: item.email, password: item.password };
//     dispatch(loginUser(values));
//     window.open("/user/dashboard", "_blank");
//   };

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleItemsPerPageChange = (e) => {
//     setItemsPerPage(Number(e.target.value));
//     setCurrentPage(1); // Reset to first page
//   };

//   return (
//     <div className="bg-gray-900 px-4">
//       <div className="pt-5 flex flex-col md:flex-row gap-4 items-center">
//         {/* Search input */}
//         <input
//           id="search"
//           name="search"
//           value={searchQuery}
//           onChange={handleSearch}
//           type="text"
//           placeholder="Search by email or username..."
//           className="block w-full md:w-[50vh] px-2 py-1 rounded-sm border-0 text-gray-200 bg-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//         />
        
//         {/* Items per page selector */}
//         <div className="flex items-center">
//           <label htmlFor="itemsPerPage" className="text-white text-sm  mr-2">
//             Show
//           </label>
//           <select
//             id="itemsPerPage"
//             value={itemsPerPage}
//             onChange={handleItemsPerPageChange}
//             className="rounded-sm  text-gray-200 border border-white/50 bg-gray-900 px-2 py-1"
//           >
//             <option value={10}>10</option>
//             <option value={25}>25</option>
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//           </select>
//         </div>
//       </div>
     
//       {message && <SuccessAlert message={message} />}
//       {error && <ErrorAlert error={error} />}

//       <div className={` ${loading ? "h-[560px] items-center" : "h-full"}`}>
//         {loading ? (
//           <Loader />
//         ) : (
//           <div className="mt-4 flow-root">
//             <div className="overflow-x-auto">
//               <div className="py-2">
//                 <table className="divide-y divide-gray-700 w-full border border-gray-700">
//                   <thead className="border-b border-white/10 text-sm leading-6 text-white">
//                     <tr>
//                       <th className="px-2 ml-4 text-left text-white border border-gray-700">
//                         S.No
//                       </th>
//                       <th 
//                         className="px-2 ml-4 text-left text-white border border-gray-700 cursor-pointer"
//                         onClick={() => handleSort("email")}
//                       >
//                         E-mail {getSortIcon("email")}
//                       </th>
//                       <th 
//                         className="px-2 py-4 text-white border border-gray-700 text-center cursor-pointer"
//                         onClick={() => handleSort("business")}
//                       >
//                         Wallet {getSortIcon("business")}
//                       </th>
//                       <th 
//                         className="px-2 py-4 text-white border border-gray-700 text-center cursor-pointer"
//                         onClick={() => handleSort("active_plan")}
//                       >
//                         A-Wallet {getSortIcon("active_plan")}
//                       </th>
//                       <th 
//                         className="px-2 py-4 text-center text-white border border-gray-700 cursor-pointer"
//                         onClick={() => handleSort("status")}
//                       >
//                         Account Status {getSortIcon("status")}
//                       </th>
//                       <th 
//                         className="px-2 py-4 text-center text-white border border-gray-700 cursor-pointer"
//                         onClick={() => handleSort("is_active")}
//                       >
//                         Package Status {getSortIcon("is_active")}
//                       </th>
//                       <th 
//                         className="px-2 py-4 text-center text-white border border-gray-700 cursor-pointer"
//                         onClick={() => handleSort("created_at")}
//                       >
//                         Created at {getSortIcon("created_at")}
//                       </th>
//                       <th className="px-2 py-4 text-center text-white border border-gray-700">
//                         Action
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody className="divide-y divide-white/5">
//                     {allusers?.filter(item => item.role !== "admin")?.map((item, index) => (
//                       <tr
//                         key={item.id}
//                         className="hover:bg-gray-700 transition-colors duration-200"
//                       >
//                          <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           <div className="text-sm text-gray-400">
//                             {(currentPage - 1) * itemsPerPage + index + 1}
//                           </div>
//                         </td>
//                         <td className="px-4 py-4 text-left text-white border border-gray-700">
//                           <div className="flex items-center justify-center gap-x-4">
//                             <div className="truncate text-sm font-medium leading-6 text-white w-full">
//                               {item?.email}
//                               <button
//                                 onClick={() => {
//                                   navigator.clipboard.writeText(
//                                     item?.refferal_code || ""
//                                   );
//                                   alert("Copied to clipboard!");
//                                 }}
//                                 className="focus:outline-none text-white bg-indigo-600 ml-1 px-4 py-[2px] rounded-full text-[10px]"
//                               >
//                                 {item?.refferal_code}
//                               </button>
//                             </div>
//                           </div>
//                         </td>
//                         <td className="px-4 py-4 text-white border border-gray-700">
//                           <div className="flex justify-between">
//                             <button
//                               onClick={() => handleCash("credit", item)}
//                               className="bg-green-600 text-white p-2 rounded transition-all duration-200 hover:bg-green-500"
//                             >
//                               <FaArrowAltCircleUp />
//                             </button>
//                             <div>${item?.business}</div>
//                             <button
//                               onClick={() => handleCash("debit", item)}
//                               className="bg-red-600 text-white p-2 rounded transition-all duration-200 hover:bg-red-500"
//                             >
//                               <FaArrowCircleDown />
//                             </button>
//                           </div>
//                         </td>
//                         <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           <div className="text-sm text-gray-400">
//                             {item?.active_plan}
//                           </div>
//                         </td>
//                         <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           {item?.status === "block" ? (
//                             <div className="flex items-center text-white gap-2">
//                               <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
//                               Blocked
//                             </div>
//                           ) : (
//                             <div className="flex items-center text-white gap-2">
//                               <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
//                               Unblocked
//                             </div>
//                           )}
//                         </td>
//                         <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           {item?.is_active === "active" ? (
//                             <div className="flex items-center text-white gap-2">
//                               <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
//                               Activated
//                             </div>
//                           ) : (
//                             <div className="flex items-center text-white gap-2">
//                               <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
//                               Not Activated
//                             </div>
//                           )}
//                         </td>
//                         <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           <div className="text-sm text-gray-400">
//                             {new Date(item?.created_at).toLocaleDateString()}
//                           </div>
//                         </td>
//                         <td className="px-4 py-4 text-center text-white border border-gray-700">
//                           <div className="flex justify-center items-center space-x-2">
//                             <button onClick={() => handleSession(item)}>
//                               <RiLoginCircleFill />
//                             </button>
//                             <Link to={`/admin/check/profile/${item?.id}`}>
//                               <GrView />
//                             </Link>
//                             {/* <button onClick={() => handleDelete(item?.id)}>
//                               <AiFillDelete />
//                             </button> */}
//                           </div>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {modalopen && (
//         <Confirmation
//           isClose={() => setModalopen(false)}
//           deletefunction={deleteUsers}
//           id={deleteID}
//         />
//       )}
//       {cashHandle && (
//         <AdminCashHandle
//           HandleCashmodel={() => setCashHandle(null)}
//           cashHandle={cashHandle}
//           userId={userId}
//           name={name}
//           balance={balance}
//           roi={roi}
//         />
//       )}

//       {/* Enhanced Pagination Controls */}
//       {pagination && (
//         <div className="flex flex-col sm:flex-row justify-between items-center py-4 px-4 md:px-8">
//           <div className="flex mb-2 sm:mb-0">
//             <button
//               onClick={() => handlePageChange(1)}
//               disabled={currentPage === 1}
//               className="text-white bg-gray-700 px-3 py-1 rounded-l-md disabled:opacity-50"
//             >
//               First
//             </button>
//             <button
//               onClick={() => handlePageChange(pagination.prevPage || currentPage)}
//               disabled={!pagination.prevPage}
//               className="text-white bg-indigo-600 px-4 py-1 mx-1 disabled:opacity-50"
//             >
//               Previous
//             </button>
//             <button
//               onClick={() => handlePageChange(pagination.nextPage || currentPage)}
//               disabled={!pagination.nextPage}
//               className="text-white bg-indigo-600 px-4 py-1 mx-1 disabled:opacity-50"
//             >
//               Next
//             </button>
//             <button
//               onClick={() => handlePageChange(pagination.totalPages)}
//               disabled={currentPage === pagination.totalPages}
//               className="text-white bg-gray-700 px-3 py-1 rounded-r-md disabled:opacity-50"
//             >
//               Last
//             </button>
//           </div>
          
//           <div className="text-white text-sm">
//             Page {pagination.currentPage} of {pagination.totalPages} | 
//             Showing {allusers?.length} of {pagination.totalRecords} records
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }