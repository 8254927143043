import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../BaseFile/comman/Loader";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import {
  getUser,
  roiToggle,
  updateUsers,
  clearErrors,
  clearMessage,
} from "../redux/userSlice";
import { getAllTopupByid } from "../redux/topupSlice";
export default function AdminUserCheck() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleuser, loading, error, message } = useSelector(
    (state) => state.allusers
  );
  const [editUser, setEditUser] = useState([]);
  const [isOn, setIsOn] = useState();
  const { singletopup } = useSelector((state) => state.alltopup);
  const [activeTransactions, setActiveTransactions] = useState({});
  const [currentLimit, setCurrentLimit] = useState(singleuser?.limit_plan || 0);
  useEffect(() => {
    dispatch(getUser(id));
    if (id) {
      dispatch(getAllTopupByid(id));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, clearErrors, clearMessage, id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (singleuser?.active_plan == singleuser?.limit_plan) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [singleuser]);

  const handleSaveChanges = () => {
    const updatedData = {
      ...editUser,
      updated_at: new Date().toISOString(),
    };
    dispatch(
      updateUsers({
        id: id,
        updatedData: updatedData,
      })
    );
  };
  // const handleToggle = async () => {
  //   const newState = !isOn;
  //   setIsOn(newState);
  //   const roiData = { status: newState ? "ON" : "OFF", id };
  //   dispatch(roiToggle(roiData));
  // };

  // Initialize all transactions as active
  useEffect(() => {
    if (singletopup?.length) {
      const initialState = {};
      singletopup.forEach((item) => {
        initialState[item.id] = true; // All transactions start as checked/active
      });
      setActiveTransactions(initialState);
    }
  }, [singletopup]);

  // Calculate current limit based on active transactions
  useEffect(() => {
    // Start with the base limit plan
    const totalLimit = singleuser?.limit_plan || 0;
    let calculatedLimit = totalLimit;

    if (singletopup?.length) {
      singletopup.forEach((item) => {
        // If transaction is inactive, subtract its amount from the limit
        if (!activeTransactions[item.id]) {
          calculatedLimit -= parseInt(item.amount);
        }
      });
    }

    setCurrentLimit(calculatedLimit);
  }, [activeTransactions, singleuser, singletopup]);

  const handleToggle = async (id, userId, amount) => {
    const newState = !activeTransactions[id];
    const parsedAmount = parseInt(amount);

    // Update local state
    setActiveTransactions((prev) => ({
      ...prev,
      [id]: newState,
    }));

    // Calculate new limit based on toggle action
    const newLimit = newState
      ? // If turning ON, ADD the amount to the current limit
        currentLimit + parsedAmount
      : // If turning OFF, SUBTRACT the amount from the current limit
        currentLimit - parsedAmount;

    // Dispatch to Redux/backend
    const roiData = {
      status: newState ? "ON" : "OFF",
      id,
      userId,
      amount: parsedAmount,
      currentLimit: newLimit,
    };

    dispatch(roiToggle(roiData));
  };
 
  return (
    <>
      <div className={`${loading ? "h-[560px] items-center" : "h-full"}`}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="bg-gray-100 ">
              <div className="max-w-7xl bg-gray-800 text-white overflow-hidden">
                <div className="px-6 py-4">
                  {message && <SuccessAlert message={message} />}
                  {error && <ErrorAlert error={error} />}
                  {/* <div className="sm:flex justify-between gap-4 items-center mb-6">
                    <h2 className="text-xl capitalize font-semibold  ">
                      {singleuser?.username} Detail
                    </h2>
                     
                    <button
                      onClick={handleToggle}
                      className="flex items-center sm:mt-0 mt-4 gap-2 px-4 py-2 text-sm rounded-lg text-white font-semibold transition-all duration-300 bg-gray-700"
                    >
                      Manage ROI
                      <div
                        className={`relative w-12 h-6 rounded-full p-1 transition-all duration-300 ${
                          isOn ? "bg-green-500" : "bg-red-500"
                        }`}
                      >
                        <div
                          className={`absolute top-1 left-1 w-4 h-4 rounded-full bg-white transition-transform duration-300 ${
                            isOn ? "translate-x-6" : "translate-x-0"
                          }`}
                        ></div>
                      </div>
                    </button>
                  </div> */}

                  <div className="relative w-full h-full text-gray-300 bg-gray-800 p-3 shadow-md rounded-lg overflow-x-auto">
                    {loading ? (
                      <Loader />
                    ) : (
                      <table className="min-w-full table-auto border-collapse border">
                        <thead>
                          <tr>
                            <th className="p-4 border-b border-slate-200 bg-black text-sm font-normal text-white">
                              Plan
                            </th>
                            <th className="p-4 border-b border-slate-200 bg-black text-sm font-normal text-white">
                              status
                            </th>
                            <th className="p-4 border-b border-slate-200 bg-black text-sm font-normal text-white">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-gray-900">
                          {singletopup
                            ?.slice()
                            .reverse()
                            ?.filter(
                              (item) => item?.userto_email === singleuser?.email
                            )
                            .map((item, index) => (
                              <tr
                                key={index}
                                className="even:bg-gray-800 text-center"
                              >
                                <td className="px-3 py-4 text-sm text-gray-300">
                                  ${item?.amount}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-300">
                                  {activeTransactions[item.id] && item.is_active ==='active'
                                    ? "Active"
                                    : "Inactive"}
                                </td>
                                <td className="px-3 py-4 text-sm flex text-gray-300">
                                  <button
                                    onClick={() =>
                                      handleToggle(item.id, item?.userby_id, item.amount)
                                    }
                                    className="flex items-center m-auto sm:mt-0 mt-4 gap-2 px-4 py-2 text-sm rounded-lg text-white font-semibold transition-all duration-300 bg-gray-700"
                                  >
                                    {activeTransactions[item.id] && item.is_active ==='active'
                                      ? "Deactivate"
                                      : "Activate"}
                                    <div
                                      className={`relative w-12 h-6 rounded-full p-1 transition-all duration-300 ${
                                        activeTransactions[item.id] && item.is_active ==='active'
                                          ? "bg-green-500"
                                          : "bg-red-500"
                                      }`}
                                    >
                                      <div
                                        className={`absolute top-1 left-1 w-4 h-4 rounded-full bg-white transition-transform duration-300 ${
                                          activeTransactions[item.id] && item.is_active ==='active'
                                            ? "translate-x-6"
                                            : "translate-x-0"
                                        }`}
                                      ></div>
                                    </div>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="lg:flex lg:space-x-4">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Last Login</div>
                      <div className="text-white">
                        {singleuser?.last_login || " - "}
                      </div>
                    </div>
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Created At</div>
                      <div className="text-white">
                        {singleuser?.created_at || " - "}
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Verify At</div>
                      <div className="text-white">
                        {singleuser?.verify_at || " - "}
                      </div>
                    </div>
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Updated At</div>
                      <div className="text-white">
                        {singleuser?.updated_at || " - "}
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Reffer By</div>
                      <div className="text-white">
                        {singleuser?.reffer_by || " - "}
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">
                        Activation Date
                      </div>
                      <div className="text-white">
                        {singleuser?.activation_date || " - "}
                      </div>
                    </div>
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Paid Member</div>
                      <div className="text-white">
                        {singleuser?.paid_member || " - "}
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">
                        Level_Income / month
                      </div>
                      <div className="text-white">
                        {singleuser?.level_month || " - "}
                      </div>
                    </div>
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">ROI/day</div>
                      <div className="text-white">
                        {singleuser?.roi_day || " - "}
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">
                        Refferal Code
                      </div>
                      <div className="text-white">
                        {singleuser?.refferal_code || " - "}
                      </div>
                    </div>

                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">Total Team</div>
                      <div className="text-white">
                        {singleuser?.total_team || " - "}
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex lg:space-x-4 mt-2">
                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">
                        BEP20 Address
                      </div>
                      <div className="text-white">
                        {singleuser?.bep20 || " - "}
                      </div>
                    </div>

                    <div className="lg:w-1/2 flex gap-8">
                      <div className="font-medium text-white">
                        TRC20 Address
                      </div>
                      <div className="text-white">
                        {singleuser?.trc20 || " - "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[2px] bg-white w-full mx-20" />
            <div className="bg-gray-800 ">
              <div className="max-w-7xl mx-auto rounded shadow overflow-hidden">
                {message && <SuccessAlert message={message} />}
                {error && <ErrorAlert error={error} />}
                <div className="px-6 py-4">
                  <h2 className="text-xl font-semibold mb-5 text-white mb-2">
                    Editable Details
                  </h2>
                  <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Username
                      </label>
                      <input
                        type="text"
                        name="username"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Password
                      </label>
                      <input
                        type="text"
                        name="password"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Role
                      </label>
                      <select
                        name="role"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.role}
                        onChange={handleChange}
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Status
                      </label>
                      <select
                        name="status"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.status}
                        onChange={handleChange}
                      >
                        <option value="unblock">unblock</option>
                        <option value="block">Block</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Is Active
                      </label>
                      <select
                        name="is_active"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.is_active}
                        onChange={handleChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Roi Status
                      </label>
                      <select
                        name="roi_status"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.roi_status}
                        onChange={handleChange}
                      >
                        <option value="open">Open</option>
                        <option value="close">Close</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        level status
                      </label>
                      <select
                        name="level_status"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.level_status}
                        onChange={handleChange}
                      >
                        <option value="open">Open</option>
                        <option value="close">Close</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.address || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Business
                      </label>
                      <input
                        type="number"
                        name="business"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.business || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Business Balance
                      </label>
                      <input
                        type="number"
                        name="business_balance"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.business_balance || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="fullname"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.fullname || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Phone
                      </label>
                      <input
                        type="number"
                        name="phone"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.phone || ""}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Forming ROI Status
                      </label>
                      <input
                        type="number"
                        name="forming_roi_status"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.forming_roi_status || ""}
                        onChange={handleChange}
                      />
                    </div> */}
                    {/* <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Level_Income Status
                      </label>
                      <input
                        type="number"
                        name="level_income_status"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.level_income_status || ""}
                        onChange={handleChange}
                      />
                    </div> */}
                    {/* <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Zero Pin
                      </label>
                      <input
                        type="number"
                        name="zero_pin"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.zero_pin}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-white">
                        Refferal By
                      </label>
                      <input
                        type="text"
                        name="reffer_by"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        defaultValue={singleuser?.reffer_by}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end mt-6">
                    <button
                      className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
