import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="w-full shadow-md border bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
        {/*Grid*/}
        <div className="grid grid-cols-12 lg:gap-8 py-14 lg:grid-cols-8">
          <div className="mb-0 col-span-full lg:col-span-3 ">
            <Link to="/">
              <img src="/JFC.png" className="w-32" />
            </Link>
            <p className="py-4  text-gray-800 lg:max-w-xs  lg:text-left text-base">
              Trade with confidence and ease at JastroFX. We are here to help
              you grow your investments every step of the way.
            </p>
            <div className="flex  space-x-4  lg:justify-start sm:mt-0 ">
              <Link
                to="https://x.com/fx_jastro"
                className="w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[#33CCFF] hover:bg-black"
              >
                <svg
                  className="text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g id="Social Media">
                    <path
                      id="Vector"
                      d="M11.3214 8.93654L16.4919 3.05554H15.2667L10.7772 8.16193L7.1914 3.05554H3.05566L8.47803 10.7773L3.05566 16.9444H4.28097L9.022 11.5519L12.8088 16.9444H16.9446L11.3211 8.93654H11.3214ZM9.64322 10.8453L9.09382 10.0764L4.72246 3.95809H6.60445L10.1322 8.89578L10.6816 9.66469L15.2672 16.0829H13.3852L9.64322 10.8456V10.8453Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </Link>

              <Link
                to="https://www.instagram.com/jastro_fx/"
                className="relative w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[linear-gradient(45deg,#FEE411_6.9%,#FEDB16_10.98%,#FEC125_17.77%,#FE983D_26.42%,#FE5F5E_36.5%,#FE2181_46.24%,#3000DC_85.57%)] hover:bg-gradient-to-b from-black to-black"
              >
                <FaInstagram className="text-white" />
              </Link>

              <Link
                to="https://www.facebook.com/profile.php?id=100089649450798"
                className="relative w-8 h-8 rounded-full transition-all duration-500 flex justify-center items-center bg-[linear-gradient(45deg,#4267B2,#385898,#3b5998)] hover:bg-gradient-to-b from-[#29487D] to-[#1C2B56]"
              >
                <FaFacebook className="text-white" />
              </Link>
            </div>
          </div>
          <div className="w-full lg:mt-0 mt-8  lg:w-auto lg:text-left col-span-full sm:order-last sm:col-span-4 md:col-span-4 lg:order-none lg:col-span-1">
            <h4 className="text-lg text-green-600 font-medium mb-7">
              Services
            </h4>
            <ul className="text-gray-800 transition-all duration-500 text-base ">
              <li className="mb-6">
                <Link
                  to="/"
                  className="text-gray-800 hover:text-green-500 hover:underline"
                >
                  Home
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/about"
                  className=" text-gray-800 hover:text-green-500 hover:underline"
                >
                  About
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/contact"
                  className=" text-gray-800 hover:text-green-500 hover:underline"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {/*End Col*/}
          <div className="w-full  lg:mt-0 mt-8  lg:w-auto lg:text-left  col-span-full sm:col-span-4 md:col-span-4 lg:col-span-2 ">
            <h4 className="text-lg text-green-600 font-medium mb-7">Support</h4>
            <ul className="text-gray-800 transition-all duration-500 text-base ">
              <li className="mb-6">
                <Link
                  to="/terms"
                  className=" text-gray-800 hover:text-green-500 hover:underline"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="mb-6">
                <Link
                  to="/privacy"
                  className=" text-gray-800 hover:text-green-500 hover:underline"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="sm:mb-6">
                <Link
                  to="/blogs"
                  className=" text-gray-800 hover:text-green-500 hover:underline"
                >
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
          {/*End Col*/}

          {/*End Col*/}
          <div className="w-full  lg:mt-0 mt-8  lg:w-auto lg:text-left col-span-full sm:col-span-4 md:col-span-4 lg:col-span-2 ">
            <h4 className="text-lg  text-green-600 font-medium mb-7 lg:text-left">
              Newsletter
            </h4>
            <div className="l:flex flex-col items-center lg:items-start">
              <input
                type="text"
                className="w-full h-12 border text-sm border-gray-400 rounded-full py-2.5 px-5 shadow-sm text-gray-800 mb-5  lg:text-left placeholder:text-gray-400 focus:outline-none focus:border-gray-500"
                placeholder="Your email here.."
              />
              <button
                type="submit"
                className="h-11 py-3  px-6 bg-indigo-300 transition-all duration-500 shadow-md rounded-full text-sm text-white font-semibold w-fit hover:bg-indigo-700"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        {/*Grid*/}
        <div className="py-7 border-t border-red-600">
          <div className="flex items-center justify-center flex-col ">
            <span className="text-sm text-gray-800 ">
              ©<a href="https://pagedone.io/"> 2024 JastroFX.</a> All rights
              reserved. reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
