import React from 'react'
import { Header } from '../Header'
import Footer from '../Footer'

export const About = () => {
  return (
    <>
    <Header/>
    
    <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-white mt-16">
            <div class="flex flex-col lg:flex-row justify-between gap-8">
                <div class="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800  pb-4">About Us</h1>
                    <p class="font-normal text-base leading-6 text-gray-600 ">JastroFX is a trusted trading platform. We are dedicated to providing traders of all levels with the tools, support, and resources they need to succeed in the world of trading. Whether you're just starting or you're a seasoned trader, our platform is designed to make your experience smooth, secure, and rewarding.
                    </p>
                </div>
                <div class="w-full lg:w-8/12">
                    <img class="w-full h-full" src="https://i.ibb.co/FhgPJt8/Rectangle-116.png" alt="A group of People" />
                </div>
            </div>
    
            <div class="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                <div class="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800  pb-4">Our Mission
                    </h1>
                    <p class="font-normal text-base leading-6 text-gray-600 ">Our mission is to empower traders by providing them with reliable tools and resources for making smarter investment decisions. We aim to make trading simple, secure, and accessible for everyone, from beginners to experienced professionals. Our platform is built on trust, transparency, and cutting-edge technology to help you succeed in the fast-paced world of trading.
                    </p>
                </div>
                <div class="w-full lg:w-8/12 lg:pt-8">
                    <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="https://i.ibb.co/FYTKDG6/Rectangle-118-2.png" alt="Alexa featured Image" />
                            <img class="md:hidden block" src="https://i.ibb.co/zHjXqg4/Rectangle-118.png" alt="Alexa featured Image" />
                            {/* <p class="font-medium text-xl leading-5 text-gray-800  mt-4">Alexa</p> */}
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="https://i.ibb.co/fGmxhVy/Rectangle-119.png" alt="Olivia featured Image" />
                            <img class="md:hidden block" src="https://i.ibb.co/NrWKJ1M/Rectangle-119.png" alt="Olivia featured Image" />
                            {/* <p class="font-medium text-xl leading-5 text-gray-800  mt-4">Olivia</p> */}
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="https://i.ibb.co/Pc6XVVC/Rectangle-120.png" alt="Liam featued Image" />
                            <img class="md:hidden block" src="https://i.ibb.co/C5MMBcs/Rectangle-120.png" alt="Liam featued Image" />
                            {/* <p class="font-medium text-xl leading-5 text-gray-800  mt-4">Liam</p> */}
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="https://i.ibb.co/7nSJPXQ/Rectangle-121.png" alt="Elijah featured image" />
                            <img class="md:hidden block" src="https://i.ibb.co/ThZBWxH/Rectangle-121.png" alt="Elijah featured image" />
                            {/* <p class="font-medium text-xl leading-5 text-gray-800  mt-4">Elijah</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
     <Footer/>
    </>
   
  )
}
