import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Base URL for API calls
const BASE_URL = 'https://api.24fxmarkets.com/api/v1';

// Async Thunk for Creating Wallet
export const createWallet = createAsyncThunk(
    'wallet/createWallet',
    async ({UserID}, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}/wallet/create-wallet`, { UserID});
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async Thunk for Deposit
export const depositFunds = createAsyncThunk(
    'wallet/depositFunds',
    async ({ WalletID, Amount }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}/wallet/deposit`, { WalletID, Amount });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async Thunk for Withdrawal
export const withdrawFunds = createAsyncThunk(
    'wallet/withdrawFunds',
    async ({ WalletID, Amount }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}/wallet/withdraw`, { WalletID, Amount });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async Thunk for Fetching Wallet Details
export const fetchWalletDetails = createAsyncThunk(
    'wallet/fetchWalletDetails',
    async (UserID, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/wallet/${UserID}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async Thunk for Fetching Wallet Transactions
export const fetchWalletTransactions = createAsyncThunk(
    'wallet/fetchWalletTransactions',
    async ({ WalletID, page = 1, limit = 10 }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${BASE_URL}/wallet/wallet-transactions/${WalletID}`, {
                params: { page, limit }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Initial State
const initialState = {
    wallet: null,
    transactions: [],
    pagination: {
        page: 1,
        limit: 10,
        total: 0
    },
    loading: false,
    error: null
};

// Wallet Slice
const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        // Reset state to initial
        resetWalletState: (state) => {
            state.wallet = null;
            state.transactions = [];
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        // Create Wallet
        builder.addCase(createWallet.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(createWallet.fulfilled, (state, action) => {
            state.loading = false;
            state.wallet = action.payload;
            state.error = null;
        })
        .addCase(createWallet.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // Deposit Funds
        .addCase(depositFunds.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(depositFunds.fulfilled, (state, action) => {
            state.loading = false;
            // Optionally update wallet balance
            if (state.wallet) {
                state.wallet.CurrentCompoundAmount += action.meta.arg.Amount;
            }
            state.error = null;
        })
        .addCase(depositFunds.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // Withdraw Funds
        .addCase(withdrawFunds.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(withdrawFunds.fulfilled, (state, action) => {
            state.loading = false;
            // Optionally update wallet balance
            if (state.wallet) {
                state.wallet.CurrentCompoundAmount -= action.meta.arg.Amount;
            }
            state.error = null;
        })
        .addCase(withdrawFunds.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // Fetch Wallet Details
        .addCase(fetchWalletDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchWalletDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.wallet = action.payload;
            state.error = null;
        })
        .addCase(fetchWalletDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // Fetch Wallet Transactions
        .addCase(fetchWalletTransactions.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchWalletTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.transactions = action.payload.transactions;
            state.pagination = action.payload.pagination;
            state.error = null;
        })
        .addCase(fetchWalletTransactions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

// Export actions and reducer
export const { resetWalletState } = walletSlice.actions;
export default walletSlice.reducer;