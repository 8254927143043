import React from "react";
const stats = [
  { id: 1, name: "Estabished", value: "2024" },
  { id: 2, name: "Registered User", value: "50,000 +" },
  { id: 3, name: "Product", value: "46,000" },
];
const TrustedBroker = () => {
  return (
    <>
      <section className="bg-[#f3f3f3] pb-16  ">
        <div className="max-w-7xl mx-auto px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div className="mt-12 md:mt-0  bg-gradient-to-r from-[#ef4444] to-green-500  relative p-[2px] rounded-md">
              <img
                src="trading.png"
                alt="About Us Image"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
            <div>
              <h2 className="font-semibold text-green-600 uppercase text-2xl md:text-3xl">
                The Future of Forex{" "}
              </h2>
              <p className="mt-4 text-gray-800 text-sm text-justify">
                Increased Global Participation: The Forex market is set to
                grow with more emerging markets participating. As economies like
                India, Brazil, and Southeast Asia expand, their currency markets
                will play a larger role in global Forex trading.
              </p>
              <p className="mt-4 text-red-600 text-sm text-justify">
                Technological Advancements: The rise of AI, machine learning,
                and blockchain is revolutionizing Forex trading. These
                technologies will enable more precise trading strategies, faster
                execution, and increased transparency through decentralized
                systems.
              </p>
              <p className="mt-4 text-green-600 text-sm text-justify">
                3.Mobile and Algorithmic Trading: Mobile apps and algorithmic
                trading are making Forex more accessible to retail investors,
                allowing them to trade on the go with automated strategies,
                increasing participation and liquidity in the market.
              </p>
              <div className="mt-5">
                <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-green-800 p-[1.5px]">
                  <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#166534_20deg,transparent_120deg)]"></div>
                  <div class="relative z-20 flex w-full justify-between rounded-[0.60rem] bg-slate-200 p-2">
                    <dl className="grid grid-cols-1 w-full px-3 gap-x-8 gap-y-16 justify-between lg:grid-cols-3">
                      {stats.map((stat) => (
                        <div key={stat.id} className="">
                          <dd className=" text-xl font-semibold tracking-tight text-green-600">
                            {stat.value}
                          </dd>
                          <dt className="text-sm  text-red-600">{stat.name}</dt>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mt-12">
                <a
                  href="#"
                  className=" uppercase hover:text-white font-medium bg-green-500 hover:bg-green-600 text-xs px-4 rounded-md text-white py-3"
                >
                  Find out more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustedBroker;
