import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3ModalState,
  useDisconnect,
} from "@web3modal/ethers/react";

// Configure Web3Modal
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "USDT Transfer App",
      description: "Transfer USDT across different networks",
    },
  }),
  chains: [
    {
      chainId: 56,
      name: "BNB Smart Chain",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: "https://bsc-dataseed.binance.org",
    },
  ],
  projectId: "b00311bb20f1d71b977b474eac2b7dcd", // Get this from cloud.walletconnect.com
});

const BSC_CHAIN_ID = "0x38"; // BSC Mainnet Chain ID
const BSC_RPC_URL = "https://bsc-dataseed.binance.org/";
const BSCSCAN_API_URL = "https://api.bscscan.com/api";

const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"; // BSC USDT
const USDT_ABI = [
  "function balanceOf(address) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function transfer(address, uint256) returns (bool)",
];

export default function Connect() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { chainId } = useWeb3ModalState();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();

  const [bnbBalance, setBnbBalance] = useState("0.00");
  const [usdtBalance, setUsdtBalance] = useState("0.00");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Improved network switching function
  const switchToBNBChain = async () => {
    if (!walletProvider) {
      console.warn("Provider not initialized");
      setError("Wallet provider not available. Please connect again.");
      return;
    }

    try {
      if (walletProvider.provider) {
        // For Web3Modal v3
        const ethersProvider = new ethers.BrowserProvider(
          walletProvider.provider
        );
        await ethersProvider.send("wallet_switchEthereumChain", [
          { chainId: BSC_CHAIN_ID },
        ]);
      } else {
        // Legacy approach
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: BSC_CHAIN_ID }],
        });
      }
    } catch (err) {
      // Check if this is because the chain hasn't been added
      if (err.code === 4902) {
        try {
          const params = [
            {
              chainId: BSC_CHAIN_ID,
              chainName: "BNB Smart Chain",
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: [BSC_RPC_URL],
              blockExplorerUrls: ["https://bscscan.com/"],
            },
          ];

          if (walletProvider.provider) {
            // For Web3Modal v3
            const ethersProvider = new ethers.BrowserProvider(
              walletProvider.provider
            );
            await ethersProvider.send("wallet_addEthereumChain", params);
          } else {
            // Legacy approach
            await walletProvider.request({
              method: "wallet_addEthereumChain",
              params,
            });
          }
        } catch (addError) {
          console.error("Failed to add BSC network:", addError);
          setError("Failed to add BSC network to your wallet");
        }
      } else {
        console.error("Failed to switch network:", err);
        setError("Failed to switch to BSC network");
      }
    }
  };

  // Enhanced balance fetching with proper error handling
  const fetchBalances = async () => {
    if (!address || !walletProvider) {
      console.warn("Account or provider not available");
      return;
    }

    try {
      // Create a provider that works with any web3 wallet
      let ethersProvider;

      if (walletProvider.provider) {
        ethersProvider = new ethers.BrowserProvider(walletProvider.provider);
      } else {
        // For most web3 wallets
        ethersProvider = new ethers.BrowserProvider(walletProvider);
      }

      // Fetch BNB balance
      const bnbBal = await ethersProvider.getBalance(address);
      setBnbBalance(ethers.formatEther(bnbBal));

      // Check if USDT contract exists at the address
      const code = await ethersProvider.getCode(USDT_ADDRESS);
      if (code === "0x") {
        console.warn("No contract found at USDT address");
        setUsdtBalance("0");
        return;
      }

      // Fetch USDT balance with safe fallbacks
      const usdtContract = new ethers.Contract(
        USDT_ADDRESS,
        USDT_ABI,
        ethersProvider
      );

      // Get decimals with fallback
      let decimals = 18;
      try {
        decimals = await usdtContract.decimals();
      } catch (decimalErr) {
        console.warn("Failed to get decimals, using default:", decimalErr);
      }

      // Get balance with fallback
      let usdtBal = ethers.parseUnits("0", decimals);
      try {
        usdtBal = await usdtContract.balanceOf(address);
      } catch (balErr) {
        console.warn("Failed to get USDT balance:", balErr);
      }

      setUsdtBalance(ethers.formatUnits(usdtBal, decimals));
    } catch (err) {
      console.error("Balance fetch error:", err);
      setError(`Failed to fetch wallet balances: ${err.message}`);
    }
  };

  // Function to fetch BNB and USDT balances

  useEffect(() => {
    if (isConnected && address) {
      fetchBalances();
    }
  }, [isConnected, address, chainId]);

  const disconnectWallet = async () => {
    try {
      await disconnect();
      setBnbBalance("0.00");
      setUsdtBalance("0.00");
    } catch (error) {
      console.error("Disconnect error:", error);
    }
  };

  return (
    <div className="w-full max-w-md border rounded-lg shadow-lg bg-white">
      <div className="p-6">
        <h2 className="text-xl font-bold mb-4">BSC Wallet</h2>
        {isConnected ? (
          <div className="space-y-4">
            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">BNB Balance</p>
              <p className="text-2xl font-bold text-yellow-600">
                {bnbBalance} BNB
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">USDT Balance</p>
              <p className="text-2xl font-bold text-green-600">
                ${usdtBalance}
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 bg-white px-3 py-2 ">
              <div className="font-semibold">
                {address?.slice(0, 6)}...{address?.slice(-4)}
              </div>
              <button
                className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition"
                onClick={disconnectWallet}
              >
                Disconnect
              </button>
            </div>
          </div>
        ) : (
          <button
            className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
}
