import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  CheckIcon,
  CreditCardIcon,
  DocumentCurrencyDollarIcon,
  ShoppingCartIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { addTopup, clearErrors, clearMessage } from "../redux/topupSlice";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../BaseFile/comman/Spinner";
import {
  CurrencyDollarIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

export default function UserPlanConfirmation({ isclose, plan, user_id }) {
  const [open, setOpen] = useState(true);
  const [amount, setAmount] = useState();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.alltopup);
  const allValues = {
    id: plan?.id,
    userby_id: user_id,
    userto_id: user_id,
    amount: amount,
  };

  const handleSaveChanges = () => {
    if (amount < plan?.min || (plan?.max !== null && amount > plan?.max)) {
      alert(
        `Amount must be between ${plan?.min} and ${
          plan?.max ? plan?.max : "infinity"
        }.`
      );
      return;
    }
    if (amount % 100 !== 0) {
      alert("Amount must be a multiple of 100 (e.g., 100, 200, 1100, etc.).");
      return;
    }

    dispatch(addTopup({ values: allValues }));
    isclose();
  };
  return (
    // <Dialog open={open} onClose={isclose} className="relative z-10">
    //   <DialogBackdrop
    //     transition
    //     className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    //   />

    //   <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
    //     <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
    //       <DialogPanel
    //         transition
    //         className="relative transform overflow-hidden rounded-lg bg-black px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
    //       >
    //         <div>
    //           <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
    //             <CheckIcon
    //               aria-hidden="true"
    //               className="h-6 w-6 text-green-600"
    //             />
    //           </div>
    //           <div className="mt-3 text-center sm:mt-5">
    //             <DialogTitle
    //               as="h3"
    //               className="text-base font-semibold leading-6 text-gray-200"
    //             >
    //               Buy Plan?
    //             </DialogTitle>
    //             <div className="mt-2">
    //               <p className="text-sm text-gray-200">
    //                 Are You Sure? Want to buy this Plan..
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //         <form
    //           onSubmit={(e) => {
    //             e.preventDefault();
    //             if (amount) {
    //               handleSaveChanges();
    //             }
    //           }}
    //         >
    //           <div className="mb-4 w-full">
    //             <label className="block text-sm font-medium text-gray-200">
    //               Amount <span className="text-red-500">*</span>
    //             </label>
    //             <input
    //               type="number"
    //               name="amount"
    //               value={amount}
    //               required
    //               onChange={(e) => setAmount(e.target.value)}
    //               className="mt-1 block h-10 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    //               placeholder="Enter Amount.."
    //               step="50"
    //               min="100"
    //             />
    //           </div>
    //           <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
    //             {loading ? (
    //               <button
    //                 disabled
    //                 className=" inline-flex w-full justify-center rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
    //               >
    //                  <Spinner/>
    //                 Please wait processing...
    //               </button>
    //             ) : (
    //               <button
    //                 type="submit"
    //                 className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
    //               >
    //                 Buy
    //               </button>
    //             )}
    //             <button
    //               type="button"
    //               onClick={isclose}
    //               className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
    //             >
    //               Cancel
    //             </button>
    //           </div>
    //         </form>
    //       </DialogPanel>
    //     </div>
    //   </div>
    // </Dialog>
    <Dialog open={open} onClose={isclose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-600 bg-opacity-50 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-xl bg-white border border-gray-100 px-6 pb-6 pt-6 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-blue-100 shadow-md">
                <CreditCardIcon
                  aria-hidden="true"
                  className="h-8 w-8 text-blue-600"
                />
              </div>
              <div className="mt-5 text-center">
                <DialogTitle
                  as="h3"
                  className="text-xl font-bold leading-6 text-gray-900"
                >
                  Confirm Your Purchase
                </DialogTitle>
                <div className="mt-3">
                  <p className="text-gray-600">
                    Please confirm the amount you wish to spend on this plan.
                  </p>
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (amount) {
                  handleSaveChanges();
                }
              }}
              className="mt-6"
            >
              <div className="mb-5 w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                  <CurrencyDollarIcon className="h-6 w-6 mr-1 text-gray-500" />
                  Amount <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    name="amount"
                    value={amount}
                    required
                    onChange={(e) => setAmount(e.target.value)}
                    className="mt-1 block h-12 w-full pl-8 bg-white border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900"
                    placeholder="Enter amount..."
                    step="50"
                    min="100"
                  />
                  <span className="absolute inset-y-0 right-3 flex items-center text-gray-400">
                    <CurrencyDollarIcon className="h-5 w-5" />
                  </span>
                </div>
                <p className="mt-2 text-xs text-gray-500 flex items-center">
                  <InformationCircleIcon className="h-3 w-3 mr-1" />
                  Minimum purchase: $100
                </p>
              </div>
              <div className="mt-8 flex flex-col-reverse gap-3 sm:flex-row sm:justify-between">
                {loading ? (
                  <button
                    disabled
                    className="inline-flex w-full justify-center items-center rounded-lg bg-blue-200 px-4 py-3 text-sm font-semibold text-white shadow-sm sm:w-1/2"
                  >
                    <Spinner className="mr-2 h-4 w-4" />
                    Processing...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center items-center rounded-lg bg-blue-600 px-4 py-3 text-sm font-semibold text-white shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200 sm:w-1/2"
                  >
                    <ShoppingCartIcon className="h-8 w-8 mr-2" />
                    Complete Purchase
                  </button>
                )}
                <button
                  type="button"
                  onClick={isclose}
                  className="inline-flex w-full justify-center items-center rounded-lg bg-white px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 transition-colors duration-200 sm:w-1/2"
                >
                  <XCircleIcon className="h-8 w-8 mr-2" />
                  Cancel
                </button>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
