import { useState, useEffect } from "react";
import Loader from "../BaseFile/comman/Loader";
import { CheckIcon } from "@heroicons/react/20/solid";
import Spinner from "../BaseFile/comman/Spinner";
import UserPlanConfirmation from "./UserPlanConfirmation";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import UserEntryFeeConfirmation from "./UserEntryFeeConfirmation";
import { getAllPlans } from "../redux/planSlice";
import { getUser } from "../redux/userSlice";
import { clearErrors, clearMessage } from "../redux/depositeSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderPopup from "../BaseFile/comman/LoaderPop";
export default function UserPlan() {
  const dispatch = useDispatch();
  const { allplans, loading } = useSelector((state) => state.allplans);
  const {
    error,
    message,
    loading: topUpLoading,
  } = useSelector((state) => state.alltopup);
  const { auth } = useSelector((state) => state.auth);
  const { singleuser } = useSelector((state) => state.allusers);

  const [planConfirm, setPlanConfirm] = useState(false);
  const [plan, setPlan] = useState();
  const [entryPlanModel, setEntryPlanModel] = useState(false);

  useEffect(() => {
    dispatch(getAllPlans());
    if (auth?.id) {
      const id = auth?.id;
      dispatch(getUser(id));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, clearErrors, clearMessage]);

  function handleBuyPlan(plan) {
    setPlan(plan);
    setPlanConfirm(true);
  }
  function isclose() {
    setPlan(null);
    setPlanConfirm(false);
    setEntryPlanModel(false);
  }

  function handleEntryPlan() {
    setEntryPlanModel(true);
  }
  const getPlanGradient = (planName) => {
    switch (planName?.toLowerCase()) {
      case "bronze":
        return "bg-gradient-to-br from-amber-200 to-amber-400";
      case "silver":
        return "bg-gradient-to-br from-gray-100 to-gray-300";
      case "golden":
        return "bg-gradient-to-br from-yellow-100 to-yellow-300";
      case "entry bot":
        return "bg-gradient-to-br from-blue-100 to-blue-300";
      case "compound plan":
        return "bg-gradient-to-br from-teal-100 to-teal-300";
      default:
        return "bg-gradient-to-br from-gray-100 to-gray-300";
    }
  };
  const getTextColor = (planName) => {
    switch (planName?.toLowerCase()) {
      case "bronze":
        return "text-amber-800";
      case "silver":
        return "text-gray-700";
      case "golden":
        return "text-yellow-700";
      case "entry bot":
        return "text-blue-700";
      case "compound plan":
        return "text-teal-700";
      default:
        return "text-gray-700";
    }
  };
  const getButtonGradient = (planName) => {
    switch (planName?.toLowerCase()) {
      case "bronze":
        return "bg-gradient-to-r from-amber-500 to-amber-700 hover:from-amber-600 hover:to-amber-800";
      case "silver":
        return "bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-500 hover:to-gray-700";
      case "golden":
        return "bg-gradient-to-r from-yellow-500 to-yellow-700 hover:from-yellow-600 hover:to-yellow-800";
      case "entry bot":
        return "bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700";
      case "compound plan":
        return "bg-gradient-to-r from-teal-400 to-teal-600 hover:from-teal-500 hover:to-teal-700";
      default:
        return "bg-gradient-to-r from-gray-400 to-gray-600 hover:from-gray-500 hover:to-gray-700";
    }
  };
  const compoundPlan = allplans?.find((item) => item.name === "Compound Plan");

  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <LoaderPopup isLoading={topUpLoading} text="Processing Payment" />
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        // <div className="bg-gray-200 py-1">
        //   <div className="mx-auto max-w-7xl px-6 lg:px-8">
        //     <div className=" isolate mx-auto mt-10 grid max-w-md grid-cols-1 my-2   gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
        //       {singleuser?.entry_fees == 0
        //         ? allplans
        //             ?.filter((item) => item.name == "entry bot")
        //             ?.map((allPlan) => (
        //               <div className="transition-transform duration-300 p-8 bg-gradient-to-r from-gray-900 to-indigo-900 border rounded-2xl shadow-xl transform hover:scale-105">
        //                 <div className="flex justify-between">
        //                   <h3 className="text-center text-gray-300 text-2xl capitalize font-semibold">
        //                     {allPlan?.name}
        //                   </h3>
        //                 </div>
        //                 <div key={allPlan?.id}>
        //                   <p className="mt-4 text-sm leading-6 text-gray-400">
        //                     {allPlan?.description}
        //                   </p>
        //                   <p className="mt-6 flex items-baseline gap-x-1">
        //                     <span className="text-3xl font-bold tracking-tight text-gray-300">
        //                       ${allPlan?.monthly_price}
        //                     </span>
        //                   </p>

        //                   <ul
        //                     role="list"
        //                     className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
        //                   >
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       5% to 12% ROI Overall
        //                     </li>
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       {allPlan?.Sponser_bonus} % Sponser Bonus
        //                     </li>
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       T & C : Participants must be at least 18 years old
        //                       to enroll in any marketing plan
        //                     </li>
        //                   </ul>
        //                   <button
        //                     onClick={() => handleEntryPlan()}
        //                     className="text-indigo-400 ring-1 ring-inset ring-indigo-200 hover:bg-indigo-900 hover:text-white  hover:ring-indigo-300 mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 w-full"
        //                   >
        //                     {loading ? <Spinner /> : "Buy Plan "}
        //                   </button>
        //                 </div>
        //               </div>
        //             ))
        //         : allplans
        //             ?.filter((item) => item.name !== "entry bot")
        //             ?.map((allPlan) => (
        //               <div className="transition-transform duration-300 p-8 bg-gradient-to-r from-gray-900 to-indigo-900 border rounded-2xl shadow-xl transform hover:scale-105">
        //                 <div className="flex justify-between">
        //                   <h3 className="text-center text-gray-300 text-2xl capitalize font-semibold">
        //                     {allPlan?.name}
        //                   </h3>
        //                 </div>
        //                 <div key={allPlan?.id}>
        //                   <p className="mt-4 text-sm leading-6 text-gray-400">
        //                     {allPlan?.description}
        //                   </p>
        //                   <p className="mt-6 flex items-baseline gap-x-1">
        //                     <span className="text-3xl font-bold tracking-tight text-gray-300">
        //                       ${allPlan?.monthly_price}
        //                     </span>
        //                   </p>

        //                   <ul
        //                     role="list"
        //                     className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
        //                   >
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       5% - 12% ROI Overall
        //                     </li>
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       {allPlan?.Sponser_bonus} % Sponser Bonus
        //                     </li>
        //                     <li className="flex gap-x-3 text-gray-300">
        //                       <CheckIcon
        //                         aria-hidden="true"
        //                         className="h-6 w-5 flex-none text-indigo-600"
        //                       />
        //                       T & C : Participants must be at least 18 years old
        //                       to enroll in any marketing plan
        //                     </li>
        //                   </ul>
        //                   <button
        //                     onClick={() => handleBuyPlan(allPlan)}
        //                     className="text-indigo-400 ring-1 ring-inset ring-indigo-200 hover:bg-indigo-900 hover:text-white  hover:ring-indigo-300 mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 w-full"
        //                   >
        //                     {loading ? <Spinner /> : "Buy Plan "}
        //                   </button>
        //                 </div>
        //               </div>
        //             ))}
        //     </div>
        //   </div>
        // </div>
        <div className="bg-gray-50 min-h-screen py-12 px-4">
          <div className="max-w-6xl mx-auto">
            <h1 className="text-4xl font-bold text-center mb-4 text-gray-800">
              Investment Plans
            </h1>
            <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
              Choose the perfect investment plan to maximize your returns and
              achieve your financial goals.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {singleuser?.entry_fees == 0
                ? allplans
                    ?.filter((item) => item.name == "entry bot")
                    .map((plan) => (
                      <div
                        key={plan.id}
                        className="rounded-2xl overflow-hidden shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl bg-white"
                      >
                        {/* Card Header with soft gradient */}
                        <div className={`${getPlanGradient(plan.name)} p-6`}>
                          <div className="flex justify-between items-start">
                            <h2
                              className={`text-2xl font-bold capitalize tracking-wider mb-2 ${getTextColor(
                                plan.name
                              )}`}
                            >
                              {plan.name}
                            </h2>
                            <div className="bg-white bg-opacity-30 rounded-full p-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="flex items-end mt-2">
                            <span
                              className={`text-4xl font-bold ${getTextColor(
                                plan.name
                              )}`}
                            >
                              ${plan.monthly_price}
                            </span>
                            {plan.id !== 4 && (
                              <span
                                className={`ml-1 text-sm opacity-80 ${getTextColor(
                                  plan.name
                                )}`}
                              >
                                min investment
                              </span>
                            )}
                          </div>
                          <p
                            className={`mt-2 text-sm ${getTextColor(
                              plan.name
                            )} opacity-90`}
                          >
                            {plan.description || "Premium investment plan"}
                          </p>
                        </div>

                        {/* Card Body */}
                        <div className="p-6">
                          <ul className="space-y-3">
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">Daily ROI</span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.ROI_day}%
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">Overall ROI</span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.ROI_overall}%
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">
                                Sponsor Bonus
                              </span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.Sponser_bonus}%
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">Plan Period</span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.plan_period} months
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">
                                Compound ROI
                              </span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.compound_roi}%
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">Bonus</span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.bonus}%
                              </span>
                            </li>
                            {plan.id !== 4 && (
                              <li className="flex justify-between items-center">
                                <span className="text-gray-600">
                                  Investment Range
                                </span>
                                <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                  ${plan.min} - ${plan.max.toLocaleString()}
                                </span>
                              </li>
                            )}
                          </ul>

                          {/* Call to Action Button */}
                          <button
                            onClick={() => handleEntryPlan()}
                            className={`w-full mt-6 py-3 px-4 rounded-lg text-white font-bold transition-colors ${getButtonGradient(
                              plan.name
                            )}`}
                          >
                            {loading ? <Spinner /> : "Buy Plan "}
                          </button>

                          {/* Terms and Conditions */}
                          <div className="mt-4 pt-4 border-t border-gray-100">
                            <p className="text-xs text-gray-500">
                              <span className="font-medium">T&C:</span>{" "}
                              Participants must be at least 18 years old to
                              enroll in any marketing plan.
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                : allplans
                    ?.filter(
                      (item) =>
                        item.name !== "entry bot" &&
                        item.name !== "Compound Plan"
                    )
                    .map((plan) => (
                      <div
                        key={plan.id}
                        className="rounded-2xl overflow-hidden shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl bg-white"
                      >
                        {/* Card Header with soft gradient */}
                        <div className={`${getPlanGradient(plan.name)} p-6`}>
                          <div className="flex justify-between items-start">
                            <h2
                              className={`text-2xl font-bold capitalize tracking-wider mb-2 ${getTextColor(
                                plan.name
                              )}`}
                            >
                              {plan.name}
                            </h2>
                            <div className="bg-white bg-opacity-30 rounded-full p-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="flex items-end mt-2">
                            <span
                              className={`text-4xl font-bold ${getTextColor(
                                plan.name
                              )}`}
                            >
                              ${plan.monthly_price}
                            </span>
                            {plan.id !== 4 && (
                              <span
                                className={`ml-1 text-sm opacity-80 ${getTextColor(
                                  plan.name
                                )}`}
                              >
                                min investment
                              </span>
                            )}
                          </div>
                          <p
                            className={`mt-2 text-sm ${getTextColor(
                              plan.name
                            )} opacity-90`}
                          >
                            {plan.description || "Premium investment plan"}
                          </p>
                        </div>

                        {/* Card Body */}
                        <div className="p-6">
                          <ul className="space-y-3">
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">Overall ROI</span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.ROI_overall}%
                              </span>
                            </li>
                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">
                                Sponsor Bonus
                              </span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.Sponser_bonus}%
                              </span>
                            </li>

                            <li className="flex justify-between items-center">
                              <span className="text-gray-600">
                                Deposit Bonus
                              </span>
                              <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                {plan.bonus}%
                              </span>
                            </li>
                            {plan.id !== 4 && (
                              <li className="flex justify-between items-center">
                                <span className="text-gray-600">
                                  Investment Range
                                </span>
                                <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                                  ${plan.min} - ${plan.max.toLocaleString()}
                                </span>
                              </li>
                            )}
                          </ul>

                          {/* Call to Action Button */}
                          <button
                            onClick={() => handleBuyPlan(plan)}
                            className={`w-full mt-6 py-3 px-4 rounded-lg text-white font-bold transition-colors ${getButtonGradient(
                              plan.name
                            )}`}
                          >
                            {loading ? <Spinner /> : "Buy Plan "}
                          </button>

                          {/* Terms and Conditions */}
                          <div className="mt-4 pt-4 border-t border-gray-100">
                            <p className="text-xs text-gray-500">
                              <span className="font-medium">T&C:</span>{" "}
                              Participants must be at least 18 years old to
                              enroll in any marketing plan.
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

              <div className="rounded-2xl overflow-hidden shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl bg-white">
                {/* Card Header with soft gradient */}
                <div className={`${getPlanGradient(compoundPlan?.name)} p-6`}>
                  <div className="flex justify-between items-start">
                    <h2
                      className={`text-2xl font-bold capitalize tracking-wider mb-2 ${getTextColor(
                        compoundPlan?.name
                      )}`}
                    >
                      {compoundPlan?.name}
                    </h2>
                    <div className="bg-white bg-opacity-30 rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="flex items-end mt-2">
                    <span
                      className={`text-4xl font-bold ${getTextColor(
                        compoundPlan?.name
                      )}`}
                    >
                      ${compoundPlan?.monthly_price}
                    </span>
                    {compoundPlan?.id && (
                      <span
                        className={`ml-1 text-sm opacity-80 ${getTextColor(
                          compoundPlan?.name
                        )}`}
                      >
                        min investment
                      </span>
                    )}
                  </div>
                  <p
                    className={`mt-2 text-sm ${getTextColor(
                      compoundPlan?.name
                    )} opacity-90`}
                  >
                    {compoundPlan?.description || "Premium investment plan"}
                  </p>
                </div>

                {/* Card Body */}
                <div className="p-6">
                  <ul className="space-y-3">
                    <li className="flex justify-between items-center">
                      <span className="text-gray-600">Overall ROI</span>
                      <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                        {compoundPlan?.compound_roi}%
                      </span>
                    </li>

                    <li className="flex justify-between items-center">
                      <span className="text-gray-600">Duration</span>
                      <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                        {compoundPlan?.plan_period} Months
                      </span>
                    </li>
                    {compoundPlan?.id && (
                      <li className="flex justify-between items-center">
                        <span className="text-gray-600">Investment Range</span>
                        <span className="font-semibold bg-gray-100 px-3 py-1 rounded-full">
                          ${compoundPlan?.min} - $
                          {compoundPlan?.max.toLocaleString()}
                        </span>
                      </li>
                    )}
                  </ul>

                  {/* Call to Action Button */}
                  <button
                    onClick={() => handleBuyPlan(plan)}
                    className={`w-full mt-6 py-3 px-4 rounded-lg text-white font-bold transition-colors ${getButtonGradient(
                      compoundPlan?.name
                    )}`}
                  >
                    {loading ? <Spinner /> : "Buy Plan "}
                  </button>

                  {/* Terms and Conditions */}
                  <div className="mt-4 pt-4 border-t border-gray-100">
                    <p className="text-xs text-gray-500">
                      <span className="font-medium">T&C:</span> Participants
                      must be at least 18 years old to enroll in any marketing
                      plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {planConfirm && (
        <UserPlanConfirmation
          isclose={isclose}
          plan={plan}
          user_id={auth?.id}
        />
      )}
      {entryPlanModel && (
        <UserEntryFeeConfirmation isclose={isclose} user_id={auth?.id} />
      )}
    </>
  );
}
