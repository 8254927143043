import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {getAllAchivers} from "../redux/achiversSlice"
import { useSelector, useDispatch } from "react-redux";
 
export const UserAchievement = () => {
  const dispatch=useDispatch()
  const {allachivers}=useSelector((state)=>state.achivers)
 
  useEffect(()=>{
    dispatch(getAllAchivers())
  },[dispatch])
  return (
    <>
      <div className=" mb-6 ">
        <div className="max-w-7xl mx-auto p-3 bg-white shadow-lg rounded-md">
          <div className="text-left">
            <h2 className="text-gray-800 text-base mb-2 font-semibold">Achievement</h2>
          </div>
          <Swiper
             spaceBetween={10}
             slidesPerView={1}
             loop={true}
             grabCursor={true}
             autoplay={{ delay: 2000, disableOnInteraction: false }}
             breakpoints={{
              440: { slidesPerView: 1 },  
               550: { slidesPerView: 2 },  
               640: { slidesPerView:3}, 
               1024: { slidesPerView:  4},

             }}
                 modules={[Autoplay, Navigation]}
             className="swiper w-full "
           >
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-8 max-sm:justify-center mt-12 ">
            {allachivers?.map((ach, index) => (
              <SwiperSlide  key={index} className="bg-gray-800 p-4 border rounded-lg ">
                <img
                  src={`/uploads/achivers/${ach?.image}`}
                  // src="/amit.png"
                  alt={ach?.image}
                  className="w-full sm:h-[140px] w-full lg:object-cover object-top rounded-lg"
                />
                <div className="text-center mt-4">
                  <h4 className="text-[12px] font-semibold text-white">{ach?.username}</h4>
                </div>
              </SwiperSlide>
            ))}
          </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};